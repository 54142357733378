<template>
  <div>
    <div class="flex flex-col">
      <div v-if="showTagField" class="flex flex-row">
        <input
          type="text"
          placeholder="Enter tag name"
          v-model="tag"
          class="form-control flex-1 mr-3"
        />
        <button @click="showTagField = false" class="btn secondary text mr-3">
          Cancel
        </button>
        <button @click="saveAutoDMTag" :disabled="isSaving || !canFormBeSubmitted" class="btn primary text">
          <span v-if="isSaving">Saving..</span>
          <span v-else>Save</span>
        </button>
      </div>
      <div>
        <p v-if="tag.length > 25" class="mt-3 mb-0 text-alert-danger">
          Please use a shorter name.
        </p>
        <p v-if="doesTagAlreadyExists" class="mt-3 mb-0 text-alert-danger">
          Please use a name you have not already used.
        </p>
      </div>
    </div>
    <div>
      <a v-if="!showTagField" @click="showTagField = true" href="javascript:;">Create New Tag</a>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { v4 as uuidv4 } from 'uuid';
  import dao from '@/dao';
  import SwalModalMixinVue from '../views/Mixins/SwalModalMixin.vue';
  import lodash from 'lodash';

  export default {
    props: {
      show: Boolean,
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      canFormBeSubmitted() {
        return this.tag.trim().length > 0 && this.tag.length <= 25 && !this.doesTagAlreadyExists;
      },
      doesTagAlreadyExists() {
        return (
          this.isSaving === false &&
          lodash
            .get(this.userProfile, 'autoDMTags', [])
            .map((tag) => tag.name)
            .includes(this.tag)
        );
      },
    },
    data() {
      return {
        isSaving: false,
        tag: '',
        showTagField: false,
      };
    },
    mounted() {},
    methods: {
      async saveAutoDMTag() {
        try {
          const tagData = {
            name: this.tag,
            id: uuidv4(),
          };
          this.isSaving = true;
          await dao.userProfile.addAutoDMTag(this.userProfile.uid, tagData);
          this.$emit('add-tag', tagData.id);
          this.$notify({ type: 'success', message: 'AutoDM tag created successfully!' });
        } catch {
          this.swalModal({
            title: 'Error',
            html: `An error has occurred while trying to create an AutoDM tag.`,
            type: 'warning',
          });
        } finally {
          this.isSaving = false;
          this.showTagField = false;
          this.tag = '';
        }
      },
    },
    mixins: [SwalModalMixinVue],
  };
</script>
