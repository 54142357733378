<script>
  import {
    getInspirationTweets,
    updateInspirationTweetInLocalStorage,
  } from '@/util/inspirationTweets';
  import { mapGetters } from 'vuex';

  export default {
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
    },
    methods: {
      async updateInspirationTweet(threadId, fieldsToUpdate) {
        const inspirationTweets = await getInspirationTweets(this.currentUser, this.userProfile.uid);

        let inspirationTweet = inspirationTweets.find((tweet) => tweet.id === threadId);

        if (inspirationTweet) {
          inspirationTweet = { ...inspirationTweet, ...fieldsToUpdate };
          updateInspirationTweetInLocalStorage(
            inspirationTweets,
            inspirationTweet,
            this.userProfile.uid
          );
        }
      },
    },
  };
</script>
