<template>
  <div>
    <div class="flex items-center justify-between">
      <div class="flex items-center font-medium leading-none">
        <span class="sm-with-130">Autoplug: automatically add a follow up tweet?</span>
        <info-tooltip
          content="We'll tweet this follow up for you when your
          original tweet gets popular!"
        ></info-tooltip>
      </div>
      <div class="flex space-x-2">
        <base-switch v-model="autoplugEnabled" @input="updateAutoplugEnabled"></base-switch>
      </div>
    </div>

    <div
      class="mt-3 mb-6 space-y-2"
      v-if="
        userProfile.autoplugTemplates &&
        userProfile.autoplugTemplates.length > 0 &&
        autoplugEnabled
      "
    >
      <base-input label="Load from template" class="mb-0 col-span-5">
        <div class="relative flex">
          <select data-cy="template-select" class="form-control" v-model="selectedTemplate">
            <option :value="null">None</option>
            <option
              v-bind:key="template.name"
              :value="template"
              v-for="template in userProfile.autoplugTemplates"
            >
              {{ template.name }}
            </option>
          </select>

          <base-button data-cy="template-delete" class="btn text secondary ml-3" @click="deleteTemplate" v-if="selectedTemplate">
            Delete
          </base-button>
        </div>
      </base-input>
    </div>

    <div class="mt-3 space-y-2" v-if="autoplugEnabled">
      <base-input ref="textarea-container" inputGroupClasses="textarea-container" alternative>
        <div class="tweet-container" ref="tweet_container">
          <vue-tribute :options="tributeOptions">
            <textarea
              data-cy="autoplug-textarea"
              dir="auto"
              class="form-control simple"
              ref="autoplugStatus"
              rows="3"
              v-model="autoplugStatus"
              placeholder="What do you want to plug? One of your Gumroad products? Your mailing list? Maybe your SoundCloud? 😎"
              id="autoplugStatus"
            ></textarea>
          </vue-tribute>
        </div>
        <div class="thread-actions flex item-center p-1">
          <div class="ml-auto flex items-center space-x-1">
            <base-button
              size="sm"
              type="secondary"
              :class="charactersLeftClass"
              class="character-count"
            >
              {{ charactersLeftCounter }}
            </base-button>
          </div>
        </div>
        <div v-show="wasTemplateEdited && autoplugStatus && autoplugStatus.trim().length" class="border-t border-divider rounded-b py-1 px-2">
          <div class="flex items-center justify-between space-x-4 bg-light-darker">
            <info-tooltip
              content="Save this tweet as a template and use it again."
            ></info-tooltip>
            <input
              data-cy="template-name"
              type="text"
              class="form-control simple"
              placeholder="Template Name..."
              v-model="templateNameInput" />
            <base-button
              size="lg"
              type="primary"
              class="btn primary small text text-xs"
              :class="{ disabled: !templateNameInput.trim().length }"
              @click="saveTemplate"
              data-cy="template-save"
            >
              Save Template
            </base-button>
          </div>
        </div>
      </base-input>

      <div class="form-group lg:flex lg:items-center lg:justify-between">
        <label for="conditionForRetweet" class="flex items-center form-control-label text-dark-mode-15">
          Delay autoplug until
          <info-tooltip
            content="Choose whether to delay your follow up tweet until this tweet reaches a minimum number of likes or retweets."
          ></info-tooltip>
        </label>
        <div class="relative mt-2 lg:mt-0 lg:w-1/2 flex items-center space-x-2">
          <div class="w-1/2">
            <base-input
              v-if="conditionForAutoplug === 'Retweets'"
              data-cy="autoplug-min-retweets"
              type="number"
              min="0"
              v-model="autoplugMinRetweets"
              name="autoplugMinRetweets"
            ></base-input>
            <base-input
              v-if="conditionForAutoplug === 'Likes'"
              data-cy="autoplug-min-likes"
              type="number"
              min="0"
              v-model="autoplugMinFavorites"
              name="autoplugMinFavorites"
            ></base-input>
          </div>
          <div class="relative w-1/2">
            <select
              data-cy="autoplug-condition-select"
              class="form-control"
              data-toggle="select"
              id="conditionForRetweet"
              v-model="conditionForAutoplug">
              <option v-for="option in conditionForAutoplugOptions"
                :key="option"
                :value="option">
                {{ option }}
              </option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-primary"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="fill-current h-4 w-4"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path></svg></div>
          </div>
        </div>
      </div>

      <div v-if="isNewComposer" class="flex justify-end">
        <button data-cy="save-button" class="btn text primary" :disabled="!canSaveAutoplugSettings" @click="sendValuesToParent(true)">
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import lodash from 'lodash';
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import dao from "@/dao";
  import { countTweetLength } from "@/../functions/src/util/countTweetLength";
  import TaggingMixin from '../../views/Mixins/TaggingMixin';
  import { mapGetters } from 'vuex';

  export default {
    name: 'autoplug-tweet',
    props: {
      thread: Object,
      value: {
        type: Boolean,
        default: false,
      },
      isNewComposer: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        autoplugEnabled: false,
        autoplugMinFavorites: 0,
        autoplugMinRetweets: 100,
        autoplugStatus: '',
        templateNameInput: '',
        selectedTemplate: null,
        templateBeingEdited: null,
        wasTemplateEdited: false,
        conditionForAutoplug: 'Likes',
        conditionForAutoplugOptions: ['Retweets', 'Likes'],
      };
    },
    mounted() {
      this.updateAutoplugEnabled();

      if (this.thread) {
        this.getValuesFromThread(this.thread);
      } else if (this.userProfile) {
        this.getValuesFromUserProfile();
      }

      if (!lodash.isNil(this.autoplugMinRetweets)) {
        this.conditionForAutoplug = 'Retweets';
        this.autoplugMinFavorites = 0;
      } else if (!lodash.isNil(this.autoplugMinFavorites)) {
        this.conditionForAutoplug = 'Likes';
        this.autoplugMinRetweets = 0;
      } else {
        this.conditionForAutoplug = 'Likes';
        this.autoplugMinFavorites = 0;
        this.autoplugMinRetweets = 0;
      }

      this.$emit(
        'updateAutoplugConditionsFromParentToChildFunction',
        (
          autoplugStatus,
          autoplugMinRetweets,
          autoplugMinFavorites,
          autoplugTemplate,
          autoplugWasTemplateEdited,
          autoplugType
        ) => {
          this.autoplugStatus = autoplugStatus;
          this.autoplugMinRetweets = Number(autoplugMinRetweets);
          this.autoplugMinFavorites = Number(autoplugMinFavorites);
          this.autoplugTemplate = autoplugTemplate;
          this.wasTemplateEdited = autoplugWasTemplateEdited;
          this.conditionForAutoplug = autoplugType;
        }
      );
    },
    methods: {
      updateAutoplugEnabled() {
        this.$emit('input', this.autoplugEnabled);
      },
      deleteTemplate() {
        this.swalModal({
          title: `Delete autoplug template`,
          text: `Are you sure you want to delete the autoplug template?`,
          type: 'warning',
          focusConfirm: false,
          showCancelButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
          reverseButtons: true,
          preConfirm: async () => {
            const templatesWithoutSelectedTemplate = this.userProfile.autoplugTemplates.filter(
              (template) => template.name !== this.selectedTemplate.name
            );
            await dao.userProfile.updateAutoplugTemplates(
              templatesWithoutSelectedTemplate,
              this.userProfile
            );
            this.selectedTemplate = null;
          },
        });
      },
      getValuesFromUserProfile() {
        const options = this.userProfile.autoplugConditions;
        if (!options) return;

        const profileTemplates = lodash.get(this.userProfile, 'autoplugTemplates', []);
        const templateFromProfile = options.templateName;
        this.selectedTemplate = options.templateName
          ? profileTemplates.find((template) => {
              return template.name === templateFromProfile;
            })
          : null;

        this.autoplugMinRetweets = lodash.get(options, 'minRetweets', null);
        this.autoplugMinFavorites = options.minFavorites;
        this.autoplugStatus = lodash.get(options, 'status', '');
        this.autoplugEnabled = true;
        this.sendValuesToParent();
      },
      getValuesFromThread(thread) {
        if (!thread.autoplug || (thread.autoplug && !thread.autoplug.status)) {
          this.autoplugEnabled = false;
          return;
        }
        this.autoplugEnabled = true;
        const templateFromThread = thread.autoplug.templateName;
        const profileTemplates = lodash.get(this.userProfile, 'autoplugTemplates', []);
        this.selectedTemplate = templateFromThread
          ? profileTemplates.find(
              (template) => template.name === templateFromThread
            )
          : null;

        this.autoplugStatus = thread.autoplug.status;
        this.autoplugMinRetweets = thread.autoplug.minRetweets;
        this.autoplugMinFavorites = thread.autoplug.minFavorites;
        this.sendValuesToParent();
      },
      async saveTemplate() {
        const templateAlreadyExists = this.userProfile.autoplugTemplates
          .some(t => t.name === this.templateNameInput.trim());
        if (templateAlreadyExists) {
          this.swalModal({
            title: 'Error',
            text: `Name already exists. Please choose a different name.`,
            type: 'error',
          });
          return;
        }
        const newTemplate = { name: this.templateNameInput.trim(), content: this.autoplugStatus };
        try {
          await dao.userProfile.createAutoplugTemplate(newTemplate, this.userProfile);
          this.selectedTemplate = newTemplate;
        } catch (error) {
          console.error('An error has occurred while creating the autoplug template', error);
          this.swalModal({
            title: 'Error',
            text: `An error has occurred while creating the autoplug template.`,
            type: 'error',
          });
        }
      },
      sendValuesToParent(isSaving) {
        if (this.isNewComposer && !isSaving) {
          return;
        }
        this.$emit(
          'values-updated',
          this.autoplugEnabled,
          this.autoplugStatus,
          this.autoplugMinRetweets,
          this.autoplugMinFavorites,
          this.selectedTemplate,
          this.wasTemplateEdited,
          this.conditionForAutoplug,
        );
      },
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      charactersLeftCounter() {
        return 280 - countTweetLength(this.autoplugStatus);
      },
      charactersLeftClass() {
        const statusLength = countTweetLength(this.autoplugStatus)
        if (statusLength >= 280) return 'character-count-exceeded';
        if (statusLength > 260) return 'character-count-warning';
        return 'bg-secondary';
      },
      canSaveAutoplugSettings() {
        const areMinFavoritesConditionsValid =
          this.conditionForAutoplug === 'Likes' &&
          !lodash.isNil(this.autoplugMinFavorites) &&
          Number(this.autoplugMinFavorites) >= 0;
        const areMinRetweetsConditionsValid =
          this.conditionForAutoplug === 'Retweets' &&
          !lodash.isNil(this.autoplugMinRetweets) &&
          Number(this.autoplugMinRetweets) >= 0;

        if (this.autoplugEnabled) {
          return (
            (areMinFavoritesConditionsValid || areMinRetweetsConditionsValid) &&
            this.autoplugStatus &&
            this.autoplugStatus.trim().length > 0
          );
        }
        return true;
      },
    },
    mixins: [TaggingMixin, SwalModalMixin],
    watch: {
      value(val) {
        this.autoplugEnabled = val;
      },
      conditionForAutoplug() {
        this.sendValuesToParent();
      },
      autoplugMinFavorites() {
        this.sendValuesToParent();
      },
      autoplugMinRetweets() {
        this.sendValuesToParent();
      },
      autoplugStatus() {
        const fromTemplate = this.selectedTemplate &&
          this.autoplugStatus === this.selectedTemplate.content;
        if (!fromTemplate) this.wasTemplateEdited = true;
        this.sendValuesToParent();
      },
      selectedTemplate(val, oldVal) {
        this.wasTemplateEdited = false;
        if (oldVal === null) {
          this.templateBeingEdited = this.autoplugStatus;
        }
        if (!val) {
          this.autoplugStatus = this.templateBeingEdited;
          this.wasTemplateEdited = true;
        } else {
          this.autoplugStatus = val.content;
        }
        this.sendValuesToParent();
      }
    }
  };
</script>

<style>
 @media only screen and (max-width: 404px) {
  .sm-with-130 {
    width: 130px;
  }
}
</style>
