<template>
  <div>
    <time-tabs>
      <tab title="Time Slots">
        <TimeSLotsSlider :midnight="midnight" v-model="time" @input="updateDate($event)" :scheduleProp="schedule" />
      </tab>
      <tab class="flex justify-between space-x-2 custom-time-container" title="Custom Time">
        <div class="custom-time relative">
          <select class="form-control" :value="selectedHour" @input="updateHour($event)">
            <option v-for="hour in hours" :key="hour">
              {{ hour }}
            </option>
          </select>
          <div
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-primary"
          >
            <!-- chevron-down.svg -->
            <inline-svg src="/img/icons/chevron-down.svg" />
          </div>
        </div>
        <div class="custom-time relative">
          <select class="form-control" :value="selectedMinute" @input="updateMinute($event)">
            <option v-for="(minute, index) in 60" :key="minute">
              {{ index | formatMinute }}
            </option>
          </select>
          <div
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-primary"
          >
            <!-- chevron-down.svg -->
            <inline-svg src="/img/icons/chevron-down.svg" />
          </div>
        </div>
      </tab>
    </time-tabs>
  </div>
</template>
<script>
  import Tab from './Tab';
  import TimeTabs from './TimeTabs';
  import TimeSLotsSlider from './TimeSlotsSlider';
  import moment from 'moment';
  import 'moment-timezone';
  import { mapGetters } from 'vuex';
  import lodashGet from 'lodash/get';
  import { Schedule } from '../models/Schedule';

  export default {
    name: 'custom-time',
    components: {
      Tab,
      TimeTabs,
      TimeSLotsSlider,
    },
    props: {
      value: {
        type: String,
      },
      schedule: {
        type: Schedule
      }
    },
    filters: {
      formatMinute: function (minute) {
        return minute.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
      },
    },
    created() {
      this.hours = [
        '00',
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23',
      ];
    },
    mounted() {
      this.dateTime = this.value;
      this.time = this.value;
    },
    methods: {
      updateDate() {
        const time = this.time.split(':');
        const hour = time[0];
        const minute = time[1].split(' ')[0];
        this.dateTime = moment(this.dateTime, 'YYYY-MM-DDTHH:mm')
          .set('hour', +hour)
          .set('minute', +minute)
          .format('YYYY-MM-DDTHH:mm');
        this.$emit('set-time-updated-manually');
        this.$emit('input', this.dateTime);
      },
      updateMinute(evt) {
        this.selectedMinute = evt.target.value;
        this.dateTime = moment(this.dateTime, 'YYYY-MM-DDTHH:mm')
          .set('minute', +this.selectedMinute)
          .format('YYYY-MM-DDTHH:mm');
        this.$emit('set-time-updated-manually');
        this.$emit('input', this.dateTime);
      },
      updateHour(evt) {
        this.selectedHour = evt.target.value;
        this.dateTime = moment(this.dateTime, 'YYYY-MM-DDTHH:mm')
          .set('hour', +this.selectedHour)
          .format('YYYY-MM-DDTHH:mm');
        this.$emit('set-time-updated-manually');
        this.$emit('input', this.dateTime);
      },
    },
    data() {
      return {
        time: '',
        dateTime: '',
        selectedHour: '',
        selectedMinute: '',
      };
    },
    watch: {
      value() {
        // We check the value's length cause we need it in this format YYYY-MM-DDTHH:mm instead of HH:mm
        if (this.value && this.value.length > 5) {
          this.dateTime = this.value;
          this.time = this.value;
          this.selectedHour = moment(this.value, 'YYYY-MM-DDTHH:mm').format('HH');
          this.selectedMinute = moment(this.value, 'YYYY-MM-DDTHH:mm').format('mm');
        }
      },
    },
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile' }),
      midnight() {
        const timezone = lodashGet(this, 'userProfile.timezone', moment.tz.guess());
        return moment.tz(this.value, timezone).startOf('day').toISOString();
      },
    },
  };
</script>