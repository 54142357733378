<template>
  <base-dropdown
    :menuClasses="`tools-dropdown-menu dropdown-menu-right dropdown-menu-${direction}`"
  >
    <button
      :data-cy="type !== 'mobile' ? 'inspiration-menu' : ''"
      class="btn text small outline px-0 w-full"
      :class="type !== 'mobile' ? 'transparent' : 'outline'"
      slot="title-container"
    >
      <div>
        <!-- options.svg -->
        <inline-svg src="/img/icons/options.svg" />
      </div>
    </button>
    <template>
      <button
        :data-cy="type !== 'mobile' ? 'inspiration-set-evergreen' : ''"
        class="dropdown-item"
        @click="$emit('toggle-favorite')"
      >
        <div :class="type === 'mobile' ? 'flex-row' : ''">
          <!-- star-empty.svg -->
          <inline-svg src="/img/icons/star-empty.svg" />
          <span>{{ inspirationTweet.isFavorite ? 'Unset as Evergreen' : 'Set as Evergreen' }}</span>
        </div>
      </button>
      <button
        :data-cy="type !== 'mobile' ? 'inspiration-categorize' : ''"
        class="dropdown-item"
        @click="$emit('open-edit-post-categories-modal')"
      >
        <div :class="type === 'mobile' ? 'flex-row' : ''">
          <!-- categories.svg -->
          <inline-svg src="/img/icons/categories.svg" />
          <span>Categorize</span>
        </div>
      </button>
      <button :data-cy="type !== 'mobile' ? 'inspiration-retweet-now' : ''" class="dropdown-item">
        <div @click="retweet" :class="type === 'mobile' ? 'flex-row' : ''">
          <!-- retweet.svg -->
          <inline-svg src="/img/icons/retweet.svg" />
          <span>Retweet Now</span>
        </div>
      </button>
      <button
        v-if="showRepostTweetOption"
        :data-cy="type !== 'mobile' ? 'inspiration-schedule-once' : ''"
        class="dropdown-item"
        @click="repostTweet"
      >
        <div :class="type === 'mobile' ? 'flex-row' : ''">
          <!-- calendar.svg -->
          <inline-svg src="/img/icons/calendar.svg" />
          <span>Re-post</span>
        </div>
      </button>
      <hr v-if="previousInspirationTweets.length" />
      <button
        v-if="previousInspirationTweets.length"
        :data-cy="type !== 'mobile' ? 'inspiration-show-previous-tweet' : ''"
        class="dropdown-item"
        @click="$emit('go-back-to-previous-inspiration-tweet')"
      >
        <div :class="type === 'mobile' ? 'flex-row' : ''">
          <!-- back.svg -->
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3 12L21 12M10 19L3 12L10 19ZM3 12L10 5L3 12Z"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span>Previous Tweet</span>
        </div>
      </button>
    </template>
  </base-dropdown>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import controller from '@/controller';

  export default {
    name: 'inspiration-options',
    props: {
      type: {
        type: String,
        default: 'desktop',
      },
      direction: {
        type: String,
        default: 'up',
      },
      inspirationTweet: {
        type: Object,
        default: null,
      },
      previousInspirationTweets: {
        type: Array,
        default: null,
      },
      showRepostTweetOption: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      ...mapState(['schedule']),
    },
    methods: {
      async retweet() {
        try {
          await controller.retweet(
            this.currentUser,
            this.inspirationTweet.id,
            this.userProfile.uid
          );
          this.$notify({ type: 'success', message: 'Retweet posted successfully!' });
          this.$eventStore.inspirationTweets.retweetNow();
        } catch (error) {
          this.$notify({
            type: 'warning',
            message: 'An error has occurred while trying to post the retweet',
          });
        }
      },
      repostTweet() {
        this.$emit('repost-tweet');
        this.$eventStore.inspirationTweets.repostTweet();
      },
    },
  };
</script>
