<template lang="html">
  <div>
    <div class="flex space-x-4 tabs tabs-border mt-6">
      <div
        v-for="(tab, index) in tabs"
        :key="tab.elm.title"
        @click="selectTab(index)"
        :class="{ 'tab-header': index == selectedIndex }"
      >
        {{ tab.elm.title }}
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'time-tabs',
    data() {
      return {
        selectedIndex: 0,
        tabs: null,
      };
    },
    mounted() {
      this.tabs = this.$slots.default ? this.$slots.default : [];
      this.selectTab(0);
    },
    methods: {
      selectTab(i) {
        this.selectedIndex = i;
        this.tabs.forEach((tab, index) => {
          tab.child.isActive = index === i;
        });
      },
    },
  };
</script>
