<template>
  <div class="tweet-previewer">
    <div v-for="(tweet, index) in tweets" :key="index" class="tweet-wrapper" :ref="`tweet-${index}`">
      <div class="twitter-user-pic">
        <img :src="user.photoURL" class="user-pic" />
        <div style="height: 5px; flex: 0 0 5px;"></div>
        <div v-if="index + 1 !== tweets.length" class="tweet-line"></div>
      </div>
      <div class="flex flex-col w-full">
        <div class="twitter-user-info">
          <div>
            <p class="twitter-user-name">
              {{ user.name }}
            </p>
            <p class="twitter-username">@{{ user.username }}</p>
          </div>
        </div>
        <div class="tweet-content pb-2">
          <div class="tweet-status break-all" v-html="getTweetStatus(tweet.status)" dir="auto" />
          <div class="pt-2" v-for="(mediaFile, i) in tweet.mediaFile" :key="i">
            <img
              v-if="
                tweet.mediaFile[i] &&
                (tweet.mediaFile[i].type === 'image' || tweet.mediaFile[i].type === 'gif')
              "
              :src="tweet.mediaFile[i].url"
            />
            <video
              v-if="tweet.mediaFile[i] && tweet.mediaFile[i].type === 'video'"
              :src="tweet.mediaFile[i].url"
            />
          </div>
          <div class="border rounded-lg p-2 mt-3 w-full" v-if="tweet.quoteTweetData">
            <div class="flex justify-between items-center">
              <div class="flex space-x-2 items-center">
                <div>
                  <img :src="tweet.quoteTweetData.userProfilePictureURL" class="h-6 w-6 rounded-full" />
                </div>
                <span class="color-white">{{ tweet.quoteTweetData.userDisplayName }}</span>
                <span class="color-dark-muted">@{{ tweet.quoteTweetData.username }}</span>
              </div>
              <base-button
                type="secondary"
                class="btn icon secondary small"
                @click="$emit('remove-quote-tweet', tweet)"
              >
                <inline-svg src="/img/icons/close.svg" />
              </base-button>
            </div>
            <div
              class="tweet-status"
              v-html="getTweetStatus(tweet.quoteTweetData.text)"
              dir="auto"
            />
          </div>
          <div class="border rounded-lg p-2 mt-3 w-full" v-if="hasThreadFinisherTweet && tweets.length - 1 === index">
            <div class="flex space-x-2 items-center">
              <div>
                <img :src="userProfile.photoURL" class="h-6 w-6 rounded-full" />
              </div>
              <span class="color-white">{{ userProfile.name }}</span>
              <span class="color-dark-muted">@{{ userProfile.username }}</span>
            </div>
            <div
              class="tweet-status"
              v-html="getTweetStatus(tweets[0].status)"
              dir="auto"
            />
          </div>
          <div class="character-left">
            <span :class="charactersLeftClass(index)">
              {{ charactersLeftCounter(index) }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { countTweetLength } from '@/../functions/src/util/countTweetLength';
  import { formatTweet } from '@/util/formatTweet';
  import ThreadMixin from '../views/Mixins/ThreadMixin';
  export default {
    name: 'tweet-previewer',
    props: {
      tweets: Array,
      hasThreadFinisherTweet: Boolean,
    },
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile' }),
      user() {
        if (this.userProfile) {
          return this.userProfile;
        } else {
          return {
            name: "Your Name",
            username: 'username',
            // Default Twitter profile picture
            photoURL: 'https://abs.twimg.com/sticky/default_profile_images/default_profile_400x400.png'
          }
        }
      }
    },
    methods: {
      getTweetStatus(status) {
        const formattedStatus = formatTweet(status);
        return formattedStatus !== ''
          ? formattedStatus.replace(/\n/g, '<br />')
          : '<span style="font-style: italic; color: hsl(206, 14%, 42%);">Write something in the composer...</span>';
      },
      charactersLeftClass(i) {
        const tweet = this.tweets[i];
        if (countTweetLength(tweet.status) >= 280) return 'twitter-character-count-exceeded';
        if (countTweetLength(tweet.status) > 260) return 'twitter-character-count-warning';
        return 'twitter-character-count';
      },
      charactersLeftCounter(i) {
        const tweet = this.tweets[i];
        return 280 - countTweetLength(tweet.status);
      },
      scrollToElement: function (val, oldVal) {
        if (oldVal && val && val.status !== oldVal.status) {
          setTimeout(() => {
            const element = this.$refs[`tweet-${val.count}`][0];
            if (
              element.getBoundingClientRect().top < window.innerHeight &&
              element.getBoundingClientRect().bottom > 0
            ) {
              return;
            }
            element.scrollIntoView({ behavior: 'auto', block: 'center' });
            element.classList.add('highlight');
            setTimeout(() => {
              element.classList.remove('highlight');
            }, 500);
          }, 0);
        }
      },
    },
    mounted() {
      this.tweets.forEach((tweet, i) => {
        this.$watch('tweets.' + i, this.scrollToElement);
      });
    },
    watch: {
      'tweets.length': function (val) {
        this.$watch('tweets.' + (val - 1), this.scrollToElement);
      },
    },
    mixins: [ThreadMixin],
  };
</script>

<style lang="scss">
  .color-dark-muted {
    color: rgba(255, 255, 255, 0.4);
  }

  .color-white {
    color: rgb(255, 255, 255);
  }

  .tweet-wrapper {
    transition: background-color 250ms linear;
  }

  .highlight {
    background-color: rgba(255, 165, 0, 0.5);
  }
</style>