<script>
  export default {
    methods: {
      addEmoji(emoji) {
        const that = this;

        function updateStatusWithEmoji() {
          const status = that.status;
          const position = that.$refs.tweet.selectionStart;
          that.status = [
            status.slice(0, position),
            emoji.native,
            status.slice(position)
          ].join('');
        }

        function focusBackOnTheTweetBox() {
          const caretPosition = that.$refs.tweet.selectionStart;

          setTimeout(() => {
            that.$refs.tweet.focus();
            that.$refs.tweet.selectionEnd = caretPosition + 2;
          }, 0);
        }

        updateStatusWithEmoji();
        focusBackOnTheTweetBox();
        that.updateStatus();
      }
    }
  }
</script>