<script>
  import { countTweetLength } from '@/../functions/src/util/countTweetLength';

  export default {
    computed: {
      charactersLeftClass() {
        if (
          countTweetLength(this.status) +
            (this.mentionedUserLength ? this.mentionedUserLength : 0) >=
          280
        )
          return 'character-count-exceeded';
        if (
          countTweetLength(this.status) +
            (this.mentionedUserLength ? this.mentionedUserLength : 0) >
          260
        )
          return 'character-count-warning';
        return 'bg-light';
      },
      charactersLeftCounter() {
        return (
          280 -
          (countTweetLength(this.status) +
            (this.mentionedUserLength ? this.mentionedUserLength : 0))
        );
      },
    },
  };
</script>
