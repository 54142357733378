<template>
  <div
    class="modal fade"
    :class="[
      { 'show d-block': show },
      { 'd-none': !show },
      { 'modal-mini': type === 'mini' },
      { 'global-modal': isGlobal },
    ]"
    v-show="show"
    tabindex="0"
    role="dialog"
    :aria-hidden="!show"
    @keydown.esc="closeModal"
  >
    <div
      class="flex justify-center w-full"
      :style="[ isFocusModeEnabled  ? { 'height' : '100%' } : { 'max-height' : '90vh' } ]"
    >
    <div
      class="modal-dialog modal-dialog-centered"
      :class="[{ 'modal-notice': type === 'notice', [`modal-${size}`]: size }, modalClasses]"
    >
      <div
        class="modal-content"
        :class="[gradient ? `bg-gradient-${gradient}` : '', modalContentClasses]"
      >
        <div class="md:hidden block">
          <slot name="close-icon"></slot>
        </div>

        <div class="tabs-modal-header" :class="[headerClasses]" v-if="$slots.header && $slots.tabs">
          <div class="flex justify-between" :class="{ 'focus-mode-hide': selectedIndex !== 0 }">
            <slot name="header"></slot>
            <slot name="close-button">
              <button
                data-cy="modal-close"
                type="button"
                class="focus--hide"
                v-if="showClose"
                @click="closeModal"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span :aria-hidden="!show">
                  <!-- close.svg -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M6 18L18 6M6 6l12 12"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </span>
              </button>
            </slot>
          </div>
          <div class="flex justify-between mt-4 tabs-container focus--hide" v-if="tabs">
            <div class="flex justify-between space-x-4 tabs">
              <div
                :class="{
                  'tab-header': index == selectedIndex,
                  'disabled': !enabled,
                  updated: mustVisitAdvancedTab && tab.elm.title == 'Advanced',
                }"
                v-for="(tab, index) in tabs"
                :key="index"
                @click="selectTab(index)"
                data-cy="tab-title"
              >
                {{ tab.elm.title }}
              </div>
            </div>
            <div data-cy="time-selector" class="text-sm">
              <slot name="dateTime"></slot>
            </div>
          </div>
        </div>

        <div class="modal-body without-scrollbar" :class="bodyClasses">
          <div class="modal-body-container" :class="{'min-height-80vh' : isFocusModeEnabled}">
            <div class="m-auto min-width-600">
              <slot name="focus"></slot>
              <slot name="tabs"></slot>
              <slot name="actions"></slot>
            </div>
            <slot name="tweet-previewer"></slot>
          </div>
        </div>

        <div class="md:block hidden">
          <slot name="close-icon"></slot>
        </div>

        <div class="modal-footer" :class="footerClasses" v-if="$slots.footer">
          <slot name="footer"></slot>
        </div>

        <slot name="overlay"></slot>

        <slot name="fade"></slot>
      </div>
    </div>
    </div>

    <slot name="previewer"></slot>
  </div>
</template>
<script>
  export default {
    name: 'tabs-modal',
    props: {
      show: Boolean,
      showClose: {
        type: Boolean,
        default: true,
      },
      type: {
        type: String,
        default: '',
        validator(value) {
          let acceptedValues = ['', 'notice', 'mini'];
          return acceptedValues.indexOf(value) !== -1;
        },
        description: 'Modal type (notice|mini|"") ',
      },
      modalClasses: {
        type: [Object, String],
        description: 'Modal dialog css classes',
      },
      size: {
        type: String,
        description: 'Modal size',
        validator(value) {
          let acceptedValues = ['', 'sm', 'lg'];
          return acceptedValues.indexOf(value) !== -1;
        },
      },
      modalContentClasses: {
        type: [Object, String],
        description: 'Modal dialog content css classes',
      },
      gradient: {
        type: String,
        description: 'Modal gradient type (danger, primary etc)',
      },
      headerClasses: {
        type: [Object, String],
        description: 'Modal Header css classes',
      },
      bodyClasses: {
        type: [Object, String],
        description: 'Modal Body css classes',
      },
      footerClasses: {
        type: [Object, String],
        description: 'Modal Footer css classes',
      },
      animationDuration: {
        type: Number,
        default: 500,
        description: 'Modal transition duration',
      },
      mustVisitAdvancedTab: {
        type: Boolean,
        default: false,
      },
      isFocusModeEnabled: {
        type: Boolean,
        default: false,
      },
      enabled: {
        type: Boolean,
        default: true
      },
      isGlobal: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      closeModal() {
        this.$emit('update:show', false);
        this.$emit('close');
      },
      selectTab(i) {
        this.selectedIndex = i;
        this.tabs.forEach((tab, index) => {
          tab.child.isActive = index === i;
        });
      },
    },
    watch: {
      show(val) {
        let documentClasses = document.body.classList;
        if (val) {
          documentClasses.add('modal-open');
        } else {
          documentClasses.remove('modal-open');
        }
      },
    },
    computed: {
      slots() {
        return Object.keys(this.$slots);
      },
    },
    data() {
      return {
        tabs: null,
        selectedIndex: 0,
      };
    },
    mounted() {
      this.tabs = this.$slots.tabs ? this.$slots.tabs : [];
      this.selectTab(0);
    },
  };
</script>

<style lang="scss">
  .focus-mode-hide {
    align-items: center;
    .modal-title {
      .btn {
        display: none !important;
      }
    }
  }
  .min-height-80vh {
    min-height: 80vh;
  }
</style>