<template>
  <div v-if="!disabled">
    <div class="row">
      <div class="flex items-center justify-between">
        <div class="flex items-center font-weight-medium leading-none">
          <span>Delay between Tweets</span>
          <info-tooltip
            content="Create hype and improve engagement by posting a drip thread"
          ></info-tooltip>
        </div>
        <div class="flex space-x-2">
          <div
            class="border rounded-full border-divider-darker px-2 text-secondary cursor-pointer text-xs pt-px hover:border-divider hover:text-muted"
            v-on:click="showDelayBetweenTweetsBlock = !showDelayBetweenTweetsBlock"
            v-show="delayBetweenTweetsEnabled"
            data-cy="delay_expand"
          >
            <span v-if="!showDelayBetweenTweetsBlock && delayBetweenTweetsEnabled">Edit</span>
            <span v-else-if="showDelayBetweenTweetsBlock && delayBetweenTweetsEnabled">Hide</span>
          </div>
          <base-switch
            class="align-middle"
            v-model="delayBetweenTweetsEnabled"
            @input="updateEnabled"
          ></base-switch>
        </div>
      </div>
    </div>
    <div 
      v-if="showDelayBetweenTweetsBlock && delayBetweenTweetsEnabled"
      class="mt-4 mb-3 space-y-2">
      <div class="relative mt-2 lg:mt-0 flex items-center space-x-2">
        <label
          data-cy="compose-delay-label"
          class="custom-option"
          v-for="(option, optionName) in delayBetweenTweetsOptions"
          :key="option">
          <input
            type="radio"
            name="delayBetweenTweets"
            :id="option"
            :value="optionName"
            :disabled="disabled"
            v-model="delayBetweenTweets"
            @change="updateParentValues">

          <span>{{ option }}</span>
        </label>
      </div>
    </div>
    <div
      v-if="showDelayBetweenTweetsBlock && delayBetweenTweetsEnabled && delayBetweenTweets === 'custom'"
      class="form-group lg:flex lg:items-center lg:justify-between">
      <label for="conditionForRetweet" class="flex items-center form-control-label text-dark-mode-15">
        Custom Delay
        <info-tooltip
          content="Choose a custom time delay between tweets"
        ></info-tooltip>
      </label>
      <div class="relative mt-2 lg:mt-0 lg:w-1/2 flex items-center space-x-2">
        <div class="w-1/2">
          <base-input
            type="number"
            min="0"
            v-model="customDelayBetweenTweets"
            @input="updateParentValues"
            name="customDelayBetweenTweets"
          ></base-input>
        </div>
        <div class="relative w-1/2">
          <select
            class="form-control"
            data-toggle="select"
            id="conditionForDelayBetweenTweets"
            v-model="conditionForDelayBetweenTweets" @change="updateParentValues">
            <option v-for="option in conditionForDelayBetweenTweetsOptions"
              :key="option"
              :value="option">
              {{ option }}
            </option>
          </select>
          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-primary"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="fill-current h-4 w-4"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path></svg></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import lodash from 'lodash';
import { mapGetters } from 'vuex';
export default {
  props: {
    thread: Object,
    disabled: Boolean,
    tweets: Array,
    value: Boolean,
  },
  watch: {
    value(val) {
      this.delayBetweenTweetsEnabled = val;
    },
    disabled: function () {
      this.updateValuesFromUserProfile();
    },
    userProfile: function () {
      this.updateValuesFromUserProfile();
    },
    thread: function (val) {
      this.updateValuesFromThread(val);
    },
    delayBetweenTweetsEnabled: function (val) {
      if (this.hasModalJustOpened) {
        this.hasModalJustOpened = false;
        return;
      } else if (val) {
        this.showDelayBetweenTweetsBlock = true;
      }
    },
  },
  data() {
    return {
      delayBetweenTweetsEnabled: false,
      delayBetweenTweets: 0,
      delayBetweenTweetsOptions: {
        1: '1m',
        2: '2m',
        5: '5m',
        15: '15m',
        60: '1hr',
        'custom': 'Custom'
      },
      conditionForDelayBetweenTweets: 'Minutes',
      conditionForDelayBetweenTweetsOptions: ['Minutes', 'Hours'],
      customDelayBetweenTweets: 0,
      showDelayBetweenTweetsBlock: false,
      hasModalJustOpened: true,
    };
  },
  computed: {
    ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
  },
  mounted() {
    if (!this.thread || !this.thread.delayBetweenTweets) {
      this.updateValuesFromUserProfile(this.userProfile);
    }
  },
  updated() {
    if (!this.delayBetweenTweetsEnabled && !this.disabled) {
      this.hasModalJustOpened = false;
    }
  },
  methods: {
    updateParentValues() {
      let delay = parseInt(this.delayBetweenTweets);
      if (this.delayBetweenTweets === 'custom') {
        delay =
          this.conditionForDelayBetweenTweets === 'Hours'
            ? parseInt(this.customDelayBetweenTweets) * 60
            : parseInt(this.customDelayBetweenTweets);
      }

      this.$emit('values-set', delay, this.delayBetweenTweetsEnabled);
    },
    updateDelayBetweenTweets(delay) {
      if ([1, 2, 5, 15, 30, 60].every(d => delay !== d)) {
        this.delayBetweenTweets = 'custom';
        this.customDelayBetweenTweets = delay;
      } else {
        this.delayBetweenTweets = delay;
      }
    },
    updateValuesFromThread(thread) {
      if (!thread) {
        return;
      }
      if (!thread.delayBetweenTweets) {
        const delayFromUserProfile = lodash.get(
          this.userProfile,
          'delayBetweenTweetsConditions.delayBetweenTweets',
          0
        );

        this.updateDelayBetweenTweets(delayFromUserProfile);

        this.delayBetweenTweetsEnabled = false;
        return;
      }

      this.updateDelayBetweenTweets(thread.delayBetweenTweets);
      this.delayBetweenTweetsEnabled = true;

      this.updateParentValues();
    },
    updateValuesFromUserProfile() {
      if (!this.userProfile.delayBetweenTweetsConditions || this.disabled) {
        this.updateParentValues();
        return;
      }

      const delayFromUserProfile = lodash.get(
        this.userProfile,
        'delayBetweenTweetsConditions.delayBetweenTweets',
        0
      );

      this.updateDelayBetweenTweets(delayFromUserProfile);

      this.delayBetweenTweetsEnabled = lodash.get(
        this.userProfile,
        'delayBetweenTweetsConditions.delayBetweenTweetsEnabled',
        false
      );
      this.updateParentValues();
    },
    updateEnabled() {
      this.$emit('input', this.delayBetweenTweetsEnabled);
      this.updateParentValues();
    },
  },
};
</script>