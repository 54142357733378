<template>
  <div v-if="slots.length === 0" class="slide-container--empty">
    No timeslots available for the selected date.
  </div>
  <div v-else class="slide-container">
    <div @click="previous" class="control" :class="{ [`disabled`]: scrollPosition === 0 }">
      <!-- chevron-left.svg -->
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.75 10.5L5.25 7L8.75 3.5"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div id="slide" ref="slide" class="space-x-2">
      <div class="custom-button-radio" v-for="(slot, index) in slots" :key="index">
        <label class="">
          <input
            type="radio"
            :value="getTime(slot.time, 24)"
            v-model="selectedSlot"
            @input="updateDate($event)"
          />
          <span :title="getTime(slot.time)">{{ formatTimeWithUserLocale(slot.time) }}</span>
        </label>
      </div>
    </div>
    <div @click="next" class="control" :class="{ [`disabled`]: isEndOfSlide }">
      <!-- chevron-right.svg -->
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.25 3.5L8.75 7L5.25 10.5"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </div>
</template>

<script>
  import moment from 'moment';
  import { mapState } from 'vuex';
  import { Schedule } from '../models/Schedule';
  import TimeFormatterMixin from '../views/Mixins/TimeFormatterMixin.vue';

  export default {
    name: 'time-slots-slider',
    data() {
      return {
        selectedSlot: '',
        scrollPosition: 0,
        isEndOfSlide: false,
      };
    },
    props: {
      value: {
        type: String,
      },
      midnight: {
        type: String,
      },
      scheduleProp: {
        type: Schedule,
      },
    },
    watch: {
      value(val) {
        this.selectedSlot = moment(val, 'YYYY-MM-DDTHH:mm').format('HH:mm A');
        this.checkEndOfSlide();
      },
    },
    methods: {
      getTime(dateTime, hourType) {
        const timeFormat = hourType === 24 ? 'HH:mm A' : 'hh:mm A';
        return dateTime.format(timeFormat);
      },
      updateDate(evt) {
        this.$emit('input', evt.target.value);
      },
      previous() {
        this.scroll('left');
        this.isEndOfSlide = false;
      },
      next() {
        this.scroll('right');
      },
      checkEndOfSlide() {
        const el = this.$refs.slide;
        if (el && el.offsetWidth >= this.slots.length * 88) {
          this.isEndOfSlide = true;
        } else {
          this.isEndOfSlide = false;
        }
      },
      scroll(direction, speed = 25, distance = 200, step = 10) {
        const element = this.$refs.slide;
        let scrollAmount = 0;
        this.isEndOfSlide = false;
        const that = this;
        if (element.offsetWidth + element.scrollLeft >= element.scrollWidth) {
          this.isEndOfSlide = true;
        }
        const slideTimer = setInterval(function () {
          if (direction == 'left') {
            element.scrollLeft -= step;
            this.isEndOfSlide = false;
          } else {
            element.scrollLeft += step;
          }
          scrollAmount += step;
          if (scrollAmount >= distance) {
            clearInterval(slideTimer);
          }
          that.scrollPosition = element.scrollLeft;
        }, speed);
      },
    },
    computed: {
      ...mapState(['schedule']),
      slots() {
        let slots = [];
        if (this.scheduleProp) {
          slots = this.scheduleProp.getThreadsByDate()[this.midnight];
        } else if (this.schedule) {
          slots = this.schedule.getThreadsByDate()[this.midnight];
        }

        const emptySlots = slots ? slots.filter((slot) => slot.slotType === 'empty') : [];
        return emptySlots;
      },
    },
    mixins: [TimeFormatterMixin]
  };
</script>
