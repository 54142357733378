<template>
  <div class="space-y-2">
    <div class="flex items-center justify-between">
      <div class="font-medium flex items-center">
        Share on Instagram
        <info-tooltip
          :content="`${
            hasMultipleTweets ? 'Tweets in this thread' : 'This tweet'
          } will be posted on Instagram as ${
            hasMultipleTweets ? 'a carousel of images' : 'an image'
          }`"
        ></info-tooltip>
      </div>
      <base-switch
        data-cy="share-on-instagram"
        v-model="shareOnInstagram"
        @input="updateEnabled"
        :disabled="disabled"
      ></base-switch>
    </div>
    <div v-if="shareOnInstagram && !disabled" class="flex flex-col space-y-2">
      <base-input ref="textarea-container" alternative class="relative">
        <textarea
          data-cy="instagram-caption"
          dir="auto"
          class="form-control"
          ref="instagramCaption"
          rows="3"
          v-model="instagramCaption"
          placeholder="Caption goes here..."
          id="instagramCaption"
          maxlength="2200"
          @input="isNewComposer ? null : updateParentValues()"
        ></textarea>
      </base-input>

      <div v-if="hasMultipleTweets" class="space-y-2">
        <div class="flex items-center justify-between">
          <p class="font-weight-medium flex items-center">Add custom last carousel slide for Instagram</p>
          <base-switch
            v-model="hasInstagramThreadFinisher"
            :disabled="!canAddInstagramThreadFinisher"
          />
        </div>
        <base-input
          v-if="hasInstagramThreadFinisher"
          inputGroupClasses="textarea-container"
          alternative
        >
          <textarea
            data-cy="instagram-thread-finisher"
            dir="auto"
            class="form-control simple"
            rows="3"
            v-model="threadFinisherText"
            placeholder="Promote yourself here..."
          />
          <div class="thread-actions flex item-center p-1">
            <div class="ml-auto flex items-center space-x-1">
              <div :class="threadFinisherCharactersLeftClass" class="character-count">
                {{ threadFinisherCharactersLeftCounter }}
              </div>
            </div>
          </div>
        </base-input>
      </div>
      <div
        data-cy="instagram-error"
        v-if="validationMessage"
        class="text-badge-error text-right pt-2"
      >
        {{ validationMessage }}
      </div>

      <div v-if="isNewComposer" class="flex justify-end">
        <button
          data-cy="save-button"
          class="btn text primary"
          :disabled="!canSaveInstagramData"
          @click="
            () =>
              $emit(
                'values-set',
                shareOnInstagram,
                instagramCaption,
                hasInstagramThreadFinisher ? threadFinisherText : null
              )
          "
        >
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { getUnfinishedPost } from '@/util/unfinishedPosts';
  import { mapGetters } from 'vuex';
  import lodash from 'lodash';
  import InstagramMixin from '../../views/Mixins/InstagramMixin.vue';
  import { countTweetLength } from '@/../functions/src/util/countTweetLength';

  export default {
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile' }),
      canSaveInstagramData() {
        return (this.instagramCaption.match(/#/g) || []).length <= 30 &&
          (this.instagramCaption.match(/@/g) || []).length <= 20 &&
          this.instagramCaption.length <= 2200 &&
          this.hasInstagramThreadFinisher
          ? countTweetLength(this.threadFinisherText) > 0 &&
              countTweetLength(this.threadFinisherText) <= 280
          : true;
      },
      threadFinisherCharactersLeftClass() {
        if (countTweetLength(this.threadFinisherText) >= 280) return 'character-count-exceeded';
        if (countTweetLength(this.threadFinisherText) > 280 - 20) return 'character-count-warning';
        return 'bg-light';
      },
      threadFinisherCharactersLeftCounter() {
        return 280 - countTweetLength(this.threadFinisherText);
      },
    },
    mounted() {
      if (!this.thread) {
        this.updateValuesFromUserProfile();
      } else {
        this.hasInstagramThreadFinisher = Boolean(this.thread.instagramThreadFinisherText);
        this.threadFinisherText = this.thread.instagramThreadFinisherText || '';
      }
      this.$emit(
        'updateInstagramInfoFromParentToChildFunction',
        (caption, instagramThreadFinisherText, canAddInstagramThreadFinisher) => {
          this.instagramCaption = caption;
          this.canAddInstagramThreadFinisher = canAddInstagramThreadFinisher;
          this.hasInstagramThreadFinisher =
            Boolean(instagramThreadFinisherText) && canAddInstagramThreadFinisher;
          this.threadFinisherText = instagramThreadFinisherText || '';
        }
      );
    },
    props: {
      disabled: Boolean,
      thread: Object,
      value: {
        type: Boolean,
        default: false,
      },
      isNewComposer: {
        type: Boolean,
        default: false,
      },
      hasMultipleTweets: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        shareOnInstagram: getUnfinishedPost() ? getUnfinishedPost().shareOnInstagram : false,
        instagramCaption: '',
        threadFinisherText: '',
        hasInstagramThreadFinisher: false,
        canAddInstagramThreadFinisher: true,
      };
    },
    methods: {
      updateEnabled() {
        this.$emit('input', this.shareOnInstagram);
      },
      updateParentValues() {
        this.$emit(
          'values-set',
          this.shareOnInstagram,
          this.instagramCaption,
          this.hasInstagramThreadFinisher ? this.threadFinisherText : null
        );
      },
      updateValuesFromUserProfile() {
        this.instagramCaption = lodash.get(
          this,
          'userProfile.integrations.instagram.instagramCaption',
          ''
        );
        this.shareOnInstagram = lodash.get(
          this,
          'userProfile.settings.isShareOnInstagramEnabled',
          false
        );
        if (!this.instagramCaption) return;
        this.updateParentValues();
      },
      updateValuesFromThread(thread) {
        this.shareOnInstagram = thread.shareOnInstagram;
        this.instagramCaption = thread.instagramCaption;
        this.canAddInstagramThreadFinisher = thread.hasThreadFinisherTweet
          ? thread.tweets.length - 1 < 10
          : thread.tweets.length < 10;
        this.hasInstagramThreadFinisher =
          Boolean(thread.instagramThreadFinisherText) && this.canAddInstagramThreadFinisher;
        this.updateParentValues();
      },
    },
    watch: {
      value(val) {
        this.shareOnInstagram = val;
      },
      thread: {
        handler: function (val) {
          if (!val) return;
          this.updateValuesFromThread(val);
        },
        immediate: true,
      },
      threadFinisherText(val) {
        if (this.isNewComposer) {
          return;
        }
        this.$emit('values-set', this.shareOnInstagram, this.instagramCaption, val);
      },
      hasInstagramThreadFinisher(val) {
        if (this.isNewComposer) {
          return;
        }
        this.$emit(
          'values-set',
          this.shareOnInstagram,
          this.instagramCaption,
          val ? this.threadFinisherText : null
        );
      },
      hasMultipleTweets(val) {
        if (!val) {
          this.hasInstagramThreadFinisher = false;
        }
      }
    },
    mixins: [InstagramMixin],
  };
</script>
