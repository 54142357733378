const GifSelector = {
  currentQuery: "",
  matchingGifs: {},
  trending: {},
  selectedCallback: null,
  wrapperElement: null,
  columns: 3,
  rowsEnabled: true
};

GifSelector.searchForGifs = async (queryString) => {
  if (queryString == '' && GifSelector.trending
    && GifSelector.trending.results) {
    return GifSelector.trending;
  }
  GifSelector.setLoading(true);
  const response = await GifSelector.searchCallbacK(queryString);
  GifSelector.setLoading(false);
  return response;
};

GifSelector.loadTrending = async () => {
  GifSelector.setLoading(true);
  const response = await GifSelector.trendingCallback();
  GifSelector.setLoading(false);
  return response;
};

GifSelector.setLoading = (loading) => {
  try {
    if (loading) {
      GifSelector.wrapperElement.classList.add('loading');
    } else {
      GifSelector.wrapperElement.classList.remove('loading');
    }
    GifSelector.isLoadingCallback(loading);
  } catch (error) {
    //
  }
}

GifSelector.processSearch = async (event) => {
  const q = event.target.value;
  GifSelector.currentQuery = q;
  GifSelector.matchingGifs = await GifSelector.searchForGifs(q);
  GifSelector.render();
};

GifSelector.processSelected = (event) => {
  const target = event.srcElement;
  const url = target.getAttribute("data-gif-selector-gif");
  GifSelector.selectedCallback(url);
};


GifSelector.loadInitialData = async () => {
  if (GifSelector.trending && GifSelector.trending.results) {
    return;
  }
  GifSelector.trending = await GifSelector.loadTrending();
};

GifSelector.renderMatchingGifs = (gifs) => {
  let gifsMarkup = "";
  let index = 0;
  gifs.results.forEach((gif) => {
    if (index % GifSelector.columns === 0 && GifSelector.rowsEnabled) {
      gifsMarkup += '<div class="gif-selector-gif-row">';
    }
    gifsMarkup += `
      <div class="gif-selector-gif-wrapper">
        <div data-cy="gif-selector-gif" class="gif-selector-gif">
          <a href="#" class="gif-selector-select-anchor" data-gif-selector-gif="${gif.media[0].tinygif.url}">
            <img class="gif-selector-image" src="${gif.media[0].nanogif.url}" data-gif-selector-gif="${gif.media[0].tinygif.url}" />
          </a>
        </div>
      </div>
    `;
    if (index % GifSelector.columns + 1 === GifSelector.columns
        && GifSelector.rowsEnabled) {
      gifsMarkup += '</div>';
    }
    index++;
  });
  const markup = `
    <div class="gif-selector-search-results">
      ${gifsMarkup}
    </div>
  `;
  const main = GifSelector.wrapperElement
    .querySelector(".gif-selector-main");
  main.innerHTML = markup;
  document
    .querySelectorAll(".gif-selector-select-anchor")
    .forEach((element) => {
      element.addEventListener("click", GifSelector.processSelected);
    });
};

GifSelector.render = () => {
  if (GifSelector.matchingGifs && GifSelector.matchingGifs.results) {
    GifSelector.renderMatchingGifs(GifSelector.matchingGifs);
    return;
  }
  if (GifSelector.trending && GifSelector.trending.results) {
    GifSelector.renderMatchingGifs(GifSelector.trending);
    return;
  }
};

GifSelector.applyCallbacks = (callbacks) => {
  GifSelector.selectedCallback = callbacks.selected;
  GifSelector.searchCallbacK = callbacks.search;
  GifSelector.trendingCallback = callbacks.trending;
  GifSelector.isLoadingCallback = callbacks.loading;
}

GifSelector.initializeBaseHTML = (wrapperElement) => {
  wrapperElement.innerHTML = `
    <div class="gif-selector">
      <div class="gif-selector-top">
        <div class="gif-selector-input">
          <input type="text" placeholder="Search Tenor" class="form-control gif-selector-search" />
        </div>
      </div>
      <div class="gif-selector-main"></div>
    </div>
  `;
}

GifSelector.enableSearch = async (wrapperElement) => {
  const search = wrapperElement.querySelector('.gif-selector-search');
  search.addEventListener("input", _.debounce(GifSelector.processSearch, 200));
  await GifSelector.loadInitialData();
  GifSelector.render();
}

GifSelector.applyOptions = (options) => {
  for (let option in options) {
    if (!option in GifSelector) {
      continue;
    }
    GifSelector[option] = options[option];
  }
}

GifSelector.initialize = async (wrapperElement, options, callbacks) => {
  if (!wrapperElement) {
    return;
  }
  GifSelector.wrapperElement = wrapperElement;
  GifSelector.applyCallbacks(callbacks);
  GifSelector.applyOptions(options);
  GifSelector.initializeBaseHTML(wrapperElement);
  GifSelector.enableSearch(wrapperElement);
};

export default GifSelector;
