<template>
  <div>
    <div class="flex flex-col" v-show="shouldShow">
      <div class="flex flex-row">
        <input
          data-cy="new-category-name-input"
          type="text"
          placeholder="Enter category name"
          v-model="category.name"
          class="form-control flex-1 mr-3"
          @keydown.enter.prevent="saveCategory"
          @input="isNameNotUnique ? resetIsNameNotUnique() : undefined"
        />
        <button
          data-cy="new-category-cancel-button"
          class="button small-button dark-mode-bg-60 mr-3 bg-red-100 text-red-500"
          v-if="forceShow"
          @click="setForceShow(false)"
        >
          Cancel
        </button>
        <button
          data-cy="new-category-save-button"
          class="button primary-button small-button"
          @click.prevent="saveCategory"
          :disabled="!canFormBeSubmitted || isSaving"
        >
          <span v-if="isSaving">Saving..</span>
          <span v-else>Save</span>
        </button>
      </div>
      <div>
        <p v-if="isCategoryNameTooLong" class="mt-3 mb-0 text-alert-danger">
          Please use a shorter name.
        </p>
        <p v-if="isNameNotUnique" class="mt-3 mb-0 text-alert-danger">
          Please use a name you have not already used.
        </p>
      </div>
    </div>
    <div v-if="canForceShow && !shouldShow">
      <a data-cy="new-category-create-link" href="#" @click.prevent="setForceShow(true)">Create New Category</a>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import dao from '@/dao';
  import ValidateCategoryMixin from '@/views/Mixins/ValidateCategoryMixin';


  export default {
    props: {
      show: Boolean,
      canForceShow: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser' }),
      shouldShow() {
        return this.show || this.forceShow;
      },
    },
    data() {
      return {
        colors: [
          '#CBD5E0',
          '#718096',
          '#F56565',
          '#ED8936',
          '#DD6B20',
          '#ECC94B',
          '#48BB78',
          '#38B2AC',
          '#667EEA',
          '#9F7AEA',
        ],
        isSaving: false,
        forceShow: false,
        category: {
          name: '',
          color: '',
        },
      };
    },
    mounted() {
      this.category.color = this.getRandomColor();
    },
    mixins: [ValidateCategoryMixin],
    methods: {
      reset() {
        this.forceShow = false;
        this.category.name = '';
      },
      getRandomColor() {
        return this.colors[(this.colors.length * Math.random()) | 0];
      },
      async saveCategory() {
        this.category.color = this.getRandomColor();
        if (!this.canFormBeSubmitted) {
          return;
        }
        const that = this;
        this.isSaving = true;
        if (await this.categoryWithSameNameExists(this.category.name)) {
          this.isSaving = false;
          return;
        }

        const shouldEnableCategory = !this.didUserReachCategoriesLimit;

        dao.categories
          .create(
            this.category.name.trim(),
            this.category.color,
            shouldEnableCategory,
            this.userProfile.uid,
          )
          .then((response) => {
            that.isSaving = false;
            that.reset();
            that.$emit('new-category-added', response.id);
          });
      },
      setForceShow(value) {
        this.forceShow = value;
      },
    },
  };
</script>
