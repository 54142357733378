<template>
  <div class="mb-4 focus--hide" v-if="inspirationTweet">
    <div
      ref="inspirationTweet"
      class="bg-inspiration rounded-lg p-2 space-y-4 md:space-y-0 md:space-x-4 md:flex">
      <div class="space-y-1 w-full p-2">
        <div class="text-muted" v-if="showInspirationTweetTime(inspirationTweet)">
          <span>Inspiration from</span>
          <a
            target="_blank"
            :href="
              'https://twitter.com/' +
              userProfile.username +
              '/status/' +
              inspirationTweet.tweetId
            "
            class="text-muted text-capitalize">
            {{ formattedTime }}
          </a>
        </div>
        <div
          data-cy="inspiration-card-body"
          class="whitespace-pre-wrap break-words"
          v-html="formatTweet(inspirationTweet.status)"
          dir="auto"
        />
      </div>
      <div class="flex p-2 justify-between md:p-0 md:justify-start md:pt-0 md:w-48 md:flex-col md:space-y-2 md:pl-2">
        <div
          class="flex space-x-2"
          v-if="inspirationTweet.favoriteCount >= 0 || inspirationTweet.retweetCount >= 0"
        >
          <div class="bg-inspiration-counter rounded w-full flex items-center text-center space-x-2 py-1 pl-1 pr-3 md:p-2 md:space-x-0 md:flex-col">
            <!-- like.svg -->
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="transform scale-75">
              <path fill="currentColor" d="M12 21a1 1 0 0 1-.71-.29l-7.77-7.78a5.26 5.26 0 0 1 0-7.4 5.24 5.24 0 0 1 7.4 0L12 6.61l1.08-1.08a5.24 5.24 0 0 1 7.4 0 5.26 5.26 0 0 1 0 7.4l-7.77 7.78A1 1 0 0 1 12 21zM7.22 6a3.2 3.2 0 0 0-2.28.94 3.24 3.24 0 0 0 0 4.57L12 18.58l7.06-7.07a3.24 3.24 0 0 0 0-4.57 3.32 3.32 0 0 0-4.56 0l-1.79 1.8a1 1 0 0 1-1.42 0L9.5 6.94A3.2 3.2 0 0 0 7.22 6z"></path>
            </svg>
            <span>{{ inspirationTweet.favoriteCount }}</span>
          </div>
          <div class="bg-inspiration-counter rounded w-full flex items-center text-center space-x-2 py-1 pl-1 pr-3 md:p-2 md:space-x-0 md:flex-col">
            <!-- retweet.svg -->
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="transform scale-75">
              <g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke="currentColor" stroke-width="2">
                <path d="M15 16l3 3 3-3"/>
                <path d="M18 17.5V7c0-1.1045695-.8954305-2-2-2h-5 0M9 8L6 5 3 8"/>
                <path d="M6 6.5V17c0 1.1045695.8954305 2 2 2h4.5"/>
              </g>
            </svg>
            <span>{{ inspirationTweet.retweetCount }}</span>
          </div>
        </div>
        <div class="flex space-x-2">
          <tooltip content="Refresh">
            <base-button data-cy="inspiration-refresh" class="btn text small outline px-0 w-full" @click="refreshInspirationTweet">
              <!-- refresh.svg -->
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="tight">
                <path fill="currentColor" d="M20.3 13.43a1 1 0 0 0-1.25.65A7.14 7.14 0 0 1 12.18 19 7.1 7.1 0 0 1 5 12a7.1 7.1 0 0 1 7.18-7 7.26 7.26 0 0 1 4.65 1.67l-2.17-.36a1 1 0 0 0-1.15.83 1 1 0 0 0 .83 1.15l4.24.7h.17a1 1 0 0 0 .34-.06.33.33 0 0 0 .1-.06.78.78 0 0 0 .2-.11l.09-.11c0-.05.09-.09.13-.15s0-.1.05-.14a1.34 1.34 0 0 0 .07-.18l.75-4a1 1 0 0 0-2-.38l-.27 1.45A9.21 9.21 0 0 0 12.18 3 9.1 9.1 0 0 0 3 12a9.1 9.1 0 0 0 9.18 9A9.12 9.12 0 0 0 21 14.68a1 1 0 0 0-.7-1.25z"></path>
              </svg>
            </base-button>
          </tooltip>
          <tooltip content="Copy">
            <base-button
              data-cy="copy-inspiration-tweet"
              class="btn text small outline px-0 w-full"
              @click.stop="
                isFromNewThreadModal === true
                  ? copyInspirationTweetAndPasteItToThread(inspirationTweet.status)
                  : copyInspirationTweet()
              "
            >
              <!-- copy.svg -->
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="tight">
                <g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" transform="translate(4 4)">
                  <polyline points="13 5 16 5 16 16 5 16 5 13"/>
                  <rect width="11" height="11"/>
                </g>
              </svg>
            </base-button>
          </tooltip>
          <inspiration-options
            :showRepostTweetOption="showRepostTweetOption"
            :direction="direction"
            :previousInspirationTweets="previousInspirationTweets"
            :inspirationTweet="inspirationTweet"
            @go-back-to-previous-inspiration-tweet="goBackToPreviousInspirationTweet"
            @toggle-favorite="toggleFavorite"
            @open-edit-post-categories-modal="openEditPostCategoriesModal" 
            @repost-tweet="repostTweet"
          />
        </div>
      </div>
    </div>

    <edit-post-categories-modal
      v-if="isEditPostCategoriesModalVisible"
      :post="inspirationTweet"
      :isFromInspirationTweet="true"
      @close="closeEditPostCategoriesModal"
      :show="isEditPostCategoriesModalVisible"
      @update-current-inspiration-tweet="updateCurrentInspirationTweet" />
  </div>
</template>

<script>
  import dao from '@/dao';
  import { mapGetters } from 'vuex';
  import moment from 'moment';
  import { getInspirationTweets } from '@/util/inspirationTweets';
  import InspirationOptions from '../InspirationOptions.vue';
  import EditPostCategoriesModal from '@/views/Modals/EditPostCategoriesModal';
  import { formatTweet } from "@/util/formatTweet";
  import InspirationTweetsMixin from '../../views/Mixins/InspirationTweetsMixin.vue';

  export default {
    data() {
      return {
        inspirationTweet: null,
        inspirationTweetIndex : null,
        previousInspirationTweets: [],
        isEditPostCategoriesModalVisible: false,
      };
    },
    name: 'inspiration-tweet',
    props: {
      direction: {
        type: String,
        default: 'down'
      },
      showRepostTweetOption: {
        type: Boolean,
        default: true
      },
      isFromNewThreadModal: {
        type: Boolean,
        default: false,
      },
    },
    components: {
      InspirationOptions,
      EditPostCategoriesModal,
    },
    mounted () {
      this.displayInspiration();
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      formattedTime() {
        if (!this.inspirationTweet.time) return null;
        if (new Date(this.inspirationTweet.time).toString() === 'Invalid Date'){
          // the date is already formatted for display
          return this.inspirationTweet.time;
        }
        return moment(new Date(this.inspirationTweet.time)).format('dddd MMMM Do [at] h:mm a');
      },
    },
    methods: {
      copyInspirationTweet() {
        this.$eventStore.copyInspirationTweet();
        this.$copyText(this.inspirationTweet.status).then(
          function () {}
        );
        return dao.userProfile.increaseInspirationTweetCopiedCount(this.userProfile.uid);
      },
      copyInspirationTweetAndPasteItToThread(tweetStatus) {
        this.copyInspirationTweet();
        this.$emit('replace-tweets-with-inspiration-tweet', tweetStatus);
      },
      async displayInspiration() {
        this.inspirationTweet = await this.getRandomInspirationTweet();
        if (!this.inspirationTweet) return;
        this.$nextTick(() => {
          if (!this.$refs.inspirationTweet) return;
          this.$refs.inspirationTweet.classList.add('show');
        });
      },
      formatTweet,
      async getRandomInspirationTweet() {
        const posts = await getInspirationTweets(this.currentUser, this.userProfile.uid);
        if (posts.length === 0) return null;

        if (this.inspirationTweet !== null) {
          this.previousInspirationTweets = [...this.previousInspirationTweets, this.inspirationTweet];
        }

        this.inspirationTweetIndex = Math.floor(Math.random() * posts.length);
        return posts[this.inspirationTweetIndex];
      },
      goBackToPreviousInspirationTweet() {
        this.inspirationTweet = this.previousInspirationTweets[this.previousInspirationTweets.length - 1];
        this.previousInspirationTweets = this.previousInspirationTweets.filter(tweet => tweet.tweetId !== this.inspirationTweet.tweetId);
      },
      async toggleFavorite() {
        try {
          await dao.posts.toggleFavorite(this.inspirationTweet.id, this.inspirationTweet.isFavorite);

          this.updateInspirationTweet(this.inspirationTweet.id, { isFavorite: !this.inspirationTweet.isFavorite });

          this.inspirationTweet = { ...this.inspirationTweet, isFavorite: !this.inspirationTweet.isFavorite };

          this.$eventStore.inspirationTweets.setTweetAsEvergreen();

          this.$notify({ type: 'success', message: `Tweet was successfully ${this.inspirationTweet.isFavorite ? 'set' : 'unset'} as evergreen` });
        } catch (error) {
          this.$notify({ type: 'warning', message: 'An error has occurred while trying to update tweet evergreen' });
        }
      },
      updateCurrentInspirationTweet(updatedInspirationTweet) {
        this.inspirationTweet = updatedInspirationTweet;
      },
      async refreshInspirationTweet() {
        this.inspirationTweet = await this.getRandomInspirationTweet();
        return dao.userProfile.increaseInspirationTweetRefreshedCount(this.userProfile.uid);
      },
      openEditPostCategoriesModal() {
        this.isEditPostCategoriesModalVisible = true;
      },
      closeEditPostCategoriesModal() {
        this.isEditPostCategoriesModalVisible = false;
      },
      reset() {
        this.inspirationTweet = null;
      },
      repostTweet() {
        this.$emit('repost-tweet', this.inspirationTweet.status);
      },
      showInspirationTweetTime(inspirationTweet) {
        return Boolean(inspirationTweet.published);
      },
    },
    mixins: [InspirationTweetsMixin]
  };
</script>
<style scoped lang="scss">
  .card-title {
    min-height: 2.5rem;

    a {
      color: inherit;
      text-decoration: none !important;
    }
  }
</style>
