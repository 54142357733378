<template>
  <tabs-modal
    ref="tabsModal"
    :show="show"
    @close="close"
    :class="[ { 'focus--on': isFocusModeEnabled, 'overlay--on': submitting || wasSubmitted || !isWholeScheduleLoaded } ]"
    :mustVisitAdvancedTab="!canFormBeSubmittedOptions()"
    :isFocusModeEnabled="isFocusModeEnabled"
    :isGlobal="isGlobalComposer"
  >

    <div
      slot="previewer"
      class="previewer h-full"
      data-cy="previewer"
      v-if="!(screenType === 'mobile') && (tweets.length >= 2 || isFocusModeEnabled)"
    >
      <tweet-previewer
        style="height: 100%;"
        :tweets="tweets"
        @remove-quote-tweet="(tweet) => tweet.quoteTweetData = null"
      />
    </div>

    <div slot="overlay" class="overlay" data-cy="overlay" v-if="submitting || wasSubmitted || !isWholeScheduleLoaded">
      <div class="loader">
        <div class="flex items-center justify-center absolute z-20 rounded-full w-20 h-20 bg-green-500 animate-grow" v-if="wasSubmitted">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" class="overflow-visible">
            <path fill="none" stroke-miterlimit="10" d="M2 16l9 9L30 6" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" stroke="#fff"/>
          </svg>
        </div>
        <span class="loading loading-lg">
          <!-- loading.svg -->
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <g fill="currentColor">
              <path opacity=".4" d="M2.9532779 14.20730183C-.4693762 11.42458165-.990022 6.37593198 1.79269817 2.9532779S9.62406802-.990022 13.0467221 1.79269817s3.9432999 7.83136985 1.16057973 11.25402393S6.37593198 16.990022 2.9532779 14.20730183zm8.83176368-10.8627782c-2.56718005-2.08719419-6.35332377-1.69674525-8.44051795.8704348s-1.69674525 6.35332376.8704348 8.44051794 6.35332376 1.69674525 8.44051794-.8704348 1.69674525-6.35332376-.8704348-8.44051794z"/>
              <path d="M14.20730183 13.0467221l-1.55182546-1.26168052c2.08719419-2.56718005 1.69674525-6.35332377-.8704348-8.44051795l1.26168053-1.55182546c3.4226541 2.78272018 3.9432999 7.83136985 1.16057973 11.25402393z"/>
            </g>
          </svg>
        </span>
      </div>
    </div>

    <div slot="header" class="modal-title">
      <div class="flex items-center">
        <span class="focus--hide">New Thread</span>
        <tooltip :content="isFocusModeEnabled ? 'Exit Focus Mode' : 'Enter Focus Mode'">
          <base-button
            class="btn icon text-muted bg-transparent focus:bg-transparent hover:bg-transparent"
            :class="[ { 'has--focus': isFocusModeEnabled } ]"
            @click="toggleFocusMode">
            <!-- focus.svg -->
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <g fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                <path d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"/>
              </g>
            </svg>
          </base-button>
        </tooltip>
      </div>
    </div>

        <div slot="focus" class="flex h-full" v-if="isFocusModeEnabled">
          <div class="textarea-container min-w-full min-h-full">
            <textarea
              dir="auto"
              ref="focus-mode-textarea"
              v-model="focusModeStatus"
              @input="updateTweets"
              @keydown.ctrl.enter="addToQueue"
              @keydown.meta.enter="addToQueue"
              class="focus-mode-textarea form-control simple"
              placeholder="Adding a new tweet to the thread is really simple: just press Enter 3 times."
            ></textarea>
          </div>
        </div>

        <tab slot="tabs" title="Compose" class="space-y-3 focus--hide">

          <gif-selector
            v-if="Number.isInteger(isSelectingGifForTweetIndex)"
            :index="isSelectingGifForTweetIndex"
            @selected-gif="addGifToTweet"
            @close-gif-selector="closeGifSelector"
          />

          <template v-if="isSelectingGifForTweetIndex === null">
            <!-- Top -->
            <compose-tweet
              v-for="(tweet, index) in tweets"
              :show="show"
              :key="tweet.guid"
              :tweet="tweet"
              :canDeleteTweet="canDeleteTweet"
              :showLinkedInBlock="showLinkedInBlock"
              :showFacebookBlock="showFacebookBlock"
              :focusOnTweetIndex="focusOnTweetIndex"
              :canAddMoreVideos="canAddMoreVideos"
              :index="index"
              :ref="`compose-tweet-${index}`"
              :showMediaButtons="!isSimpleVersion"
              @deleted="deleteTweet"
              @input-changed="onInputChange"
              @writing="watchWriting"
              @open-gif-selector="openGifSelector"
              @pasted="onPaste"
              @cleared-media="clearMediaSelectionAtIndex"
              @added-new-tweet="addTweetBox"
              @pressed-save="processSave"
              @status-updated="value => statusUpdated(value, tweet)"
              @update-poll="value => updatePoll(value, tweet)"
              @delete-poll="() => deletePoll(tweet)"
              @set-as-favorite="setFavorite"
              :disableMediaUpload="showFacebookBlock || tweetHasPoll(index)"
              :disablePollOption="tweetHasMedia(index)"
              :tweetHasPoll="tweetHasPoll(index)"
              :imageCountInThread="imageCountInThread"
              @quote-tweet-data-updated="
                (quoteURL) => quoteTweetDataUpdated(quoteURL, tweet)
              "
            />

            <inspiration-tweet
              :showRepostTweetOption="false"
              v-if="showInspirationTweets"
              :enabled="displayInspiration"
              :key="inspirationTweetComponentKey"
              :isFromNewThreadModal="true"
              @replace-tweets-with-inspiration-tweet="replaceTweetsWithInspirationTweet"
              direction="up"
            />

            <div class="flex space-x-2 pt-3 quick-actions focus--hide" v-if="!isSimpleVersion && !isCategoryPost">
              <tooltip content="Delay between tweets" v-if="tweets.length > 1">
                <div class="text-center">
                  <base-button @click="toggleDelayBetweenTweets"
                    data-cy="compose-delay-tweets"
                    class="btn icon hover:bg-accent hover:text-white"
                    :class="delayBetweenTweetsEnabled ? 'bg-accent text-white' : 'outline outline-muted'">
                    <!-- delay.svg -->
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <path d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
                    </svg>
                  </base-button>
                </div>
              </tooltip>
              <tooltip content="Share on Instagram">
                <div class="text-center">
                  <base-button @click="toggleShareOnInstagram"
                    data-cy="compose-share-instagram"
                    :disabled="!canShareOnInstagram"
                    class="btn icon hover:bg-accent hover:text-white"
                    :class="shareOnInstagram ? 'bg-accent text-white' : 'outline outline-muted'">
                    <!-- instagram.svg -->
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <g stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path d="M0 0h24v24H0z" stroke="none"/>
                        <rect x="4" y="4" width="16" height="16" rx="4"/>
                        <circle cx="12" cy="12" r="3"/>
                        <path d="M16.5 7.5v.001"/>
                      </g>
                    </svg>
                  </base-button>
                </div>
              </tooltip>
              <tooltip content="Automatically retweet">
                <div class="text-center">
                  <base-button @click="toggleConditionalRetweet"
                    data-cy="compose-auto-retweet"
                    class="btn icon hover:bg-accent hover:text-white"
                    :class="conditionalRetweetEnabled ? 'bg-accent text-white' : 'outline outline-muted'">
                    <!-- retweet.svg -->
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke="currentColor" stroke-width="2">
                        <path d="M15 16l3 3 3-3"/>
                        <path d="M18 17.5V7c0-1.1045695-.8954305-2-2-2h-5 0M9 8L6 5 3 8"/>
                        <path d="M6 6.5V17c0 1.1045695.8954305 2 2 2h4.5"/>
                      </g>
                    </svg>
                  </base-button>
                </div>
              </tooltip>
              <tooltip content="Add an autoplug">
                <div class="text-center">
                  <base-button @click="toggleAutoPlug"
                    data-cy="compose-follow-up"
                    class="btn icon hover:bg-accent hover:text-white"
                    :class="autoplugEnabled ? 'bg-accent text-white' : 'outline outline-muted'">
                    <!-- followup.svg -->
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <g stroke-linecap="round" stroke-linejoin="round" stroke-width="2" stroke="currentColor" fill="none" stroke-miterlimit="10">
                        <path d="M7 12l8-8v5c9 0 8 11 8 11s-3-5-8-5v5l-8-8z"/>
                        <path d="M10 20l-8-8 8-8"/>
                      </g>
                    </svg>
                  </base-button>
                </div>
              </tooltip>
              <tooltip :content="imageCountInThread <= 9 ? 'Post on LinkedIn' : 'LinkedIn only supports posting either 1 video or up to 9 images'">
                <div class="text-center">
                  <base-button @click="toggleLinkedIn"
                    data-cy="compose-post-linkedin"
                    :disabled="!canShareOnLinkedIn"
                    class="btn icon hover:bg-accent hover:text-white"
                    :class="showLinkedInBlock ? 'bg-accent text-white' : 'outline outline-muted'">
                    <!-- linkedin.svg -->
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <g fill="currentColor">
                        <path fill="none" d="M0 0h24v24H0z"/>
                        <path d="M18.335 18.339H15.67v-4.177c0-.996-.02-2.278-1.39-2.278-1.389 0-1.601 1.084-1.601 2.205v4.25h-2.666V9.75h2.56v1.17h.035c.358-.674 1.228-1.387 2.528-1.387 2.7 0 3.2 1.778 3.2 4.091v4.715zM7.003 8.575a1.546 1.546 0 01-1.548-1.549 1.548 1.548 0 111.547 1.549zm1.336 9.764H5.666V9.75H8.34v8.589zM19.67 3H4.329C3.593 3 3 3.58 3 4.297v15.406C3 20.42 3.594 21 4.328 21h15.338C20.4 21 21 20.42 21 19.703V4.297C21 3.58 20.4 3 19.666 3h.003z"/>
                      </g>
                    </svg>
                  </base-button>
                </div>
              </tooltip>
              <tooltip :content="hasMedia ? 'Facebook sharing is disabled when a media is attached' : 'Post on Facebook'">
                <div class="text-center">
                  <base-button @click="toggleFacebook"
                    data-cy="compose-post-facebook"
                    :disabled="hasMedia"
                    class="btn icon hover:bg-accent hover:text-white"
                    :class="showFacebookBlock ? 'bg-accent text-white' : 'outline outline-muted'">
                    <!-- linkedin.svg -->
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <g fill="currentColor">
                        <path fill="none" d="M0 0h24v24H0z"/>
                        <path d="M15.402 21v-6.966h2.333l.349-2.708h-2.682V9.598c0-.784.218-1.319 1.342-1.319h1.434V5.857a19.19 19.19 0 00-2.09-.107c-2.067 0-3.482 1.262-3.482 3.58v1.996h-2.338v2.708h2.338V21H4a1 1 0 01-1-1V4a1 1 0 011-1h16a1 1 0 011 1v16a1 1 0 01-1 1h-4.598z"/>
                      </g>
                    </svg>
                  </base-button>
                </div>
              </tooltip>
              <tooltip
                content="Send an automatic DM"
                v-if="show"
              >
                <div class="text-center">
                  <base-button
                    @click="toggleAutoDM"
                    data-cy="add-auto-dm"
                    class="btn icon hover:bg-accent hover:text-white"
                    :class="isAutoDMEnabled ? 'bg-accent text-white' : 'outline outline-muted'"
                  >
                    <inline-svg src="/img/icons/autodm.svg" />
                  </base-button>
                </div>
              </tooltip>
            </div>

            <div class="row focus--hide" v-if="isCategoryPost">
              <div class="flex items-center justify-between">
                <div class="flex items-center font-weight-medium leading-none">
                  <span>Set categories</span>
                </div>
              </div>
              <div class="py-2">
                <div class="flex flex-wrap">
                  <div class="custom-tags max-w-full overflow-hidden" v-for="category in userCategories" :key="category.id">
                    <label data-cy="category-checkbox">
                      <input type="checkbox" :value="category.id" v-model="selectedCategories"/>
                      <span :title="category.name">{{ category.name }}</span>
                    </label>
                  </div>
                </div>
                <new-category-mini-form class="mt-3" v-if="userCategories.length === 0"
                  @new-category-added="autoSelectNewCategory"/>
              </div>
            </div>
          </template>
        </tab>

        <tab slot="tabs" title="Schedule" v-if="!(isCurrentPageDrafts || isCategoryPost)">

          <div v-if="getTimeError(noEmptySlots, time)" class="text-badge-error pb-4" v-html="getTimeError(noEmptySlots, time)"></div>
          <div v-else class="text-muted pb-4">{{ scheduledDateTimeText }}</div>

          <DatePicker v-model="time"
            :model-config="{
              type: 'string',
              mask: 'YYYY-MM-DDTHH:mm',
            }"
            is-expanded
            :min-date="new Date()"
            title-position="left"
            :from-date="timeForFromPage"
            is-required
          />
          <CustomTime v-model="time" :schedule="scheduleProp" @set-time-updated-manually="wasTimeUpdatedManually = true" />
        </tab>

        <div slot="dateTime" v-if="!(isCurrentPageDrafts || isCategoryPost)">{{ scheduledDateTimeTextSimplyFormed }}</div>

        <!-- Extra -->
        <tab slot="tabs" title="Advanced" v-if="!isSimpleVersion && !isCategoryPost" class="space-y-3 focus--hide">
          <delay-between-tweets data-cy="delay-between-tweets" v-if="show" v-model="delayBetweenTweetsEnabled" :disabled="tweets.length === 1" @values-set="updateDelayBetweenTweets"></delay-between-tweets>
          <div class="advanced-options-new-thread-modal space-y-2" v-if="!isSimpleVersion && !isCategoryPost">
            <share-on-instagram data-cy="instagram" v-if="show" :disabled="!canShareOnInstagram" v-model="shareOnInstagram" @values-set="updateShareOnInstagramValues"/>
            <autoplug-tweet data-cy="follow-up-tweet" v-model="autoplugEnabled" @values-updated="processNewAutoplugValues" v-if="show"></autoplug-tweet>
            <conditional-retweets
              v-if="show"
              data-cy="auto-retweet"
              v-model="conditionalRetweetEnabled"
              @values-set="updateConditionalRetweetValues"
            />
            <linked-in
              data-cy="linkedin"
              v-if="show"
              v-model="showLinkedInBlock"
              :disabled="!canShareOnLinkedIn"
              @updateLinkedInInfoInParent="updateLinkedInInfoInParent"
              @updateLinkedInInfoFromParentToChildFunction="updateLinkedInInfoFromParentToChildFunction = $event"
            ></linked-in>
            <facebook
              data-cy="facebook"
              v-if="show"
              v-model="showFacebookBlock"
              :disabled="hasMedia"
              :tooltip="hasMedia ? 'Facebook sharing is disabled when a media is attached' : undefined"
              @updateFacebookInfoInParent="updateFacebookInfoInParent"
              @updateFacebookInfoFromParentToChildFunction="updateFacebookInfoFromParentToChildFunction = $event"
            ></facebook>
            <auto-dm
              data-cy="auto-dm"
              v-if="show"
              v-model="isAutoDMEnabled"
              @values-updated="processNewAutoDMValues"
             />
            <div class="row">
              <div class="flex items-center justify-between">
                <div class="flex items-center font-weight-medium leading-none">
                  <span>Set categories</span>
                </div>
                <base-switch data-cy="set-categories" class="align-middle" v-if="!isSimpleVersion" v-model="showCategoryBlock"></base-switch>
              </div>
              <div class="py-2" v-if="showCategoryBlock && userCategories">
                <div class="flex flex-wrap">
                  <div data-cy="category-item" class="custom-tags max-w-full overflow-hidden" v-for="category in userCategories" :key="category.id">
                    <label>
                      <input type="checkbox" :value="category.id" v-model="selectedCategories"/>
                      <span :title="category.name">{{ category.name }}</span>
                    </label>
                  </div>
                </div>
                <new-category-mini-form class="mt-3" v-if="userCategories.length === 0"
                  @new-category-added="autoSelectNewCategory"
                  />
              </div>
            </div>
          </div>

        </tab>

        <!-- Buttons -->
        <div slot="actions"
          v-if="isSelectingGifForTweetIndex === null"
          class="actions space-y-3 lg:space-y-0 flex-row-reverse"
          :class="{
            reverse: isCurrentPageDrafts,
            'mt-12': !isSimpleVersion && !isCategoryPost && !isFocusModeEnabled && !this.isCurrentPageDrafts,
            'mt-6': isSimpleVersion || this.isCurrentPageDrafts,
          }"
        >
          <base-button
            data-cy="save-as-draft"
            type="primary-negative"
            class="btn text"
            :class="{
              'pointer': canFormBeSubmittedAsADraft(),
              'primary': isCurrentPageDrafts,
              'outline': !isCurrentPageDrafts,
              'focus--hide': this.isFocusModeEnabled && !this.isCurrentPageDrafts,
            }"
            v-if="!isSimpleVersion && !isCategoryPost"
            @click="saveAsADraft"
            :disabled="!canFormBeSubmittedAsADraft()">
            <div>
              <span>Save as Draft</span>
            </div>
          </base-button>

          <div
            class="space-y-3 lg:flex lg:items-center lg:space-x-4 lg:space-y-0"
            :class="{'ml-0': isCurrentPageDrafts, 'ml-auto': !isCurrentPageDrafts}">

            <a
              data-cy="post-now-button"
              class="btn text focus--hide"
              @click="postNow"
              v-if="!isCategoryPost && !isSimpleVersion"
              :class="{
                'outline': isCurrentPageDrafts,
                'secondary': !isCurrentPageDrafts
              }"
              :disabled="!canFormBeSubmittedIgnoreTime()"
              href="javascript:;">
              <div><span>Post Now</span></div></a>

            <base-button v-if="!isCurrentPageDrafts && !isCategoryPost"
              data-cy="add-to-queue-button"
              type="primary"
              class="btn text primary"
              :class="{'pointer': canFormBeSubmitted()}"
              @click="addToQueue"
              :disabled="!canFormBeSubmitted()">
              <span>Add to Queue</span>
            </base-button>

            <base-button v-if="isCategoryPost"
              data-cy="save-post-button"
              type="primary"
              class="btn text primary"
              :class="{'pointer': canFormBeSubmitted()}"
              @click="selectedRecurrentPost ? replaceRecurrentPost() : saveAsRecurrentPost()"
              :disabled="!canFormBeSubmitted()">
              <span>Save Post</span>
            </base-button>

          </div>

        </div>

    <div slot="fade"
      class="absolute z-20 pointer-events-none bottom-0 ml-4 mr-4 h-24 -mt-24 bg-gradient-to-b from-light-transparent to-light opacity-50"></div>

  </tabs-modal>
</template>

<script>
  import lodash from 'lodash';
  import moment from 'moment';
  import 'moment-timezone';
  import { mapGetters, mapState } from 'vuex';
  import {Thread} from "../../models/Thread";
  import {DraftThread} from "../../models/DraftThread";
  import InspirationTweet from "../../components/Threads/InspirationTweet";
  import NewCategoryMiniForm from "../../components/NewCategoryMiniForm"
  import ComposeTweet from "../../components/Threads/ComposeTweet";
  import DelayBetweenTweets from "../../components/Threads/DelayBetweenTweets";
  import LinkedIn from "../../components/Threads/LinkedIn";
  import Facebook from "../../components/Threads/Facebook";
  import AutoDM from "../../components/Threads/AutoDM.vue";
  import ShareOnInstagram from "../../components/Threads/ShareOnInstagram";
  import dao from "@/dao";
  import ThreadMixin from "../Mixins/ThreadMixin";
  import TaggingMixin from "../Mixins/TaggingMixin";
  import UploadContainerMixin from "../Mixins/UploadContainerMixin";
  import { setUnfinishedPost, getUnfinishedPost, removeUnfinishedPost } from '@/util/unfinishedPosts';
  import { countTweetLength } from "@/../functions/src/util/countTweetLength";
  import SwalModalMixin from '../Mixins/SwalModalMixin.vue';
  import LinkedInMixin from '../Mixins/LinkedInMixin.vue';
  import FacebookMixin from '../Mixins/FacebookMixin.vue';
  import Tab from '../../components/Tab';
  import TabsModal from '../../components/TabsModal';
  import CustomTime from '../../components/CustomTime';
  import DatePicker from 'v-calendar/lib/components/date-picker.umd';
  import GifSelector from '@/components/Threads/GifSelector.vue';
  import TweetPreviewer from '../../components/TweetPreviewer';
  import MobileMixin from '../Mixins/MobileMixin';
  import AutoplugTweet from "../../components/Threads/AutoplugTweet";
  import ConditionalRetweets from "../../components/Threads/ConditionalRetweets";
  import { Schedule } from '../../models/Schedule';
  import { v1 as uuidv1 } from 'uuid';
  import AutoDMMixinVue from '../Mixins/AutoDMMixin.vue';
  import LogoutMixinVue from '../Mixins/LogoutMixin.vue';
  import QuoteTweetMixin from '../Mixins/QuoteTweetMixin.vue';
  import { validateLink } from '@/util/validateLink';
  import CustomerStatusMixin from '../Mixins/CustomerStatusMixin.vue';

  const fb = require('../../firebase');
  const config = require('@/config');

  export default {
    components: {
      InspirationTweet,
      LinkedIn,
      Facebook,
      ComposeTweet,
      DelayBetweenTweets,
      NewCategoryMiniForm,
      ShareOnInstagram,
      Tab,
      TabsModal,
      GifSelector,
      DatePicker,
      CustomTime,
      TweetPreviewer,
      AutoplugTweet,
      ConditionalRetweets,
      'auto-dm': AutoDM
    },
    data() {
      return {
        inspirationTweetComponentKey: 0,
        ... this.initialState(),
      };
    },
    name: "new-thread-modal",
    props: {
      selectedRecurrentPost: {
        type: Object,
      },
      isFocusModeEnabledFromParent: {
        type: Boolean,
      },
      show: Boolean,
      selectedCategoryId: String,
      timeFromParent: {
        type: Object,
      },
      isCurrentPageDrafts: {
        type: Boolean,
        default: false,
      },
      statusFromParent: {
        type: String,
      },
      isCategoryPost: {
        type: Boolean,
        default: false
      },
      isSimpleVersion: {
        type: Boolean,
        default: false
      },
      showInspirationTweets: {
        type: Boolean,
        default: true,
      },
      useLocalStorage: {
        type: Boolean,
        default: false
      },
      scheduleProp: {
        type: Schedule
      },
      isGlobalComposer: {
        type: Boolean,
        default: false
      },
      isFromInspirationPanel: {
        type: Boolean,
        default: false,
      },
    },
    watch: {
      tweets: {
        deep: true,
        handler(tweets) {
          const content = this.getTextForLinkedInAndFacebook();

          this.$nextTick(function () {
            if (this.updateLinkedInInfoFromParentToChildFunction) {
              this.updateLinkedInInfoFromParentToChildFunction(content, this.linkedInIsPostPublic);
            }

            if (this.updateFacebookInfoFromParentToChildFunction) {
              this.updateFacebookInfoFromParentToChildFunction(content);
            }
          });

          if (this.isFocusModeEnabled) {
            tweets.forEach(async (tweet) => {
              const link = tweet.status
                .split(/\s+/)
                .find(
                  (status) =>
                    (status.includes('twitter.com') || status.includes('x.com')) &&
                    validateLink(status).isValid &&
                    !status.includes('/video/') &&
                    status.includes('/status/')
                );
              if (link) {
                const quoteTweetData = await this.getQuoteTweetData(link);
                tweet.status = tweet.status.replace(link, '');
                this.focusModeStatus = this.focusModeStatus.replace(link, '');
                tweet.quoteTweetData = quoteTweetData;
              }
            });
          }
        },
      },
      shouldUpdateTime(val) {
        if (val && !this.wasTimeUpdatedManually && this.schedule) {
          this.shouldUpdateTime = false;
          const nextTimeSlot = this.schedule.getNextTimeSlot();
          if (nextTimeSlot) {
            this.time = nextTimeSlot.format('YYYY-MM-DDTHH:mm');
          }
        }
      },
      time: function (val) {
        this.currentSlotLength(val)
      },
      selectedCategoryId: function (val) {
        this.applySelectedCategory(val);
      },
      timeFromParent: function (val) {
        if (!val) return;
        this.time = this.formatTimeForInput(val);
        const that = this;
        setTimeout(function () {that.$emit('new-thread-modal-mounted')}, 1000);
      },
      show: async function (show) {
        this.isFocusModeEnabled = this.isFocusModeEnabledFromParent;

        if (!show) {
          return;
        }

        const overlay = document.getElementsByClassName('focus-mode-overlay')[0];
        if (overlay) overlay.classList.add('hidden');

        this.setTime();
        this.tweets.map(tweet => {
          if (!tweet.fileList) {
            tweet.mediaFile = [];
          }
        });

        if (this.selectedRecurrentPost) {
          this.submitting = true;

          if (this.statusFromParent) this.tweets[0].status = this.statusFromParent;

          const recurrentPostTweetsPromises = this.selectedRecurrentPost.tweets.map(async (tweet) => {
            const mediaFile = tweet.media.map((media) => {
              const mediaName = media.name;
              const mediaType = media.type;
              const mediaAltText = media.altText;
              return {
                name: mediaName,
                type: this.getMediaType(mediaType),
                url: config.buildStorageMediaURL(mediaName),
                altText: mediaAltText,
              };
            });

            // Because we're deleting the old thread, we need to re-upload the media files since they will be deleted.
            const fileListPromises = tweet.media.map(async (media) => {
              const response = await fetch(config.buildStorageMediaURL(media.name));
              const blob = await response.blob();
              const extension = media.name.split('.')[1];
              const file = new File([blob], `${uuidv1()}.${extension}`, {type: media.type});

              return file;
            });

            const fileList = await Promise.all(fileListPromises);

            return {
              ...tweet,
              fileList,
              published: false,
              mediaFile,
              media: []
            };
          });

          const recurrentPostTweets = await Promise.all(recurrentPostTweetsPromises);

          this.tweets = recurrentPostTweets;

          this.submitting = false;
        }

        if (this.selectedCategoryId) {
          this.applySelectedCategory(this.selectedCategoryId);
        }
      },
    },
    methods: {
      addToQueue() {
        if (this.time) {
          this.scheduleThread(false);
        } else {
          const time = this.schedule.getNextTimeSlot();
          const user = this.saveThreadToLocalStorage ? 'temp_user' : this.userProfile.uid;
          const thread = Thread.newThread({
            time,
            tweet: this.filterOutEmptyTweets(this.tweets),
            user,
            isFavorite: this.isFavorite,
            postNow: this.postNow,
            shareOnInstagram: this.shareOnInstagram,
            linkedIn: this.isLinkedInEnabled ? this.linkedInInfo : null,
            facebook: lodash.get(this.facebookInfo, 'text') ? this.facebookInfo : null,
            delayBetweenTweets: this.saveAndGetDelayBetweenTweets(),
            instagramCaption: this.instagramCaption,
          });

          if (this.useLocalStorage) {
            this.saveThreadToLocalStorage(thread);
            this.wasSubmitted = true;
            this.close();
            setTimeout(() => {
              this.$emit('reopen-new-thread-modal', { time: this.time, isFocusModeEnabled: this.isFocusModeEnabled });
              removeUnfinishedPost();
              this.resetModal();
              this.wasSubmitted = false;
            }, 500);
          } else {
            this.prepareThreadForSaving(thread, false)
              .then(thread => {
                if(!thread) return;
                this.$emit('scroll-to-thread', thread.id);
              });
          }
        }
        this.$emit('add-thread-to-queue');
      },
      applySelectedCategory(category) {
        this.selectedCategories = [];
        if (category !== 'non-categorized') {
          this.selectedCategories.push(category);
        }
        this.showCategoryBlock = true;
      },
      canFormBeSubmitted() {
        const tweetsOver280Chars = this.tweets.filter((t) => countTweetLength(t.status) > 280);
        return (
          tweetsOver280Chars.length === 0 &&
          !this.submitting &&
          this.isTimeValid(this.time) &&
          this.isPollsDataValid() &&
          this.filterOutEmptyTweets(this.tweets).length > 0 &&
          this.areConditionalRetweetConditionsValid() &&
          this.areAutoplugConditionsValid() &&
          this.areAutoDMConditionsValid() &&
          !this.isGifUploading &&
          this.isLinkedInInfoValid() &&
          this.isFacebookInfoValid() &&
          this.areDelayBetweenTweetsConditionsValid() &&
          this.areShareOnInstagramConditionsValid() &&
          (!this.isCategoryPost || (Array.isArray(this.categories) && this.categories.length > 0))
        );
      },
      canFormBeSubmittedIgnoreTime() {
        const tweetsOver280Chars = this.tweets.filter((t) => countTweetLength(t.status) > 280);
        return (
          tweetsOver280Chars.length === 0 &&
          !this.submitting &&
          this.isPollsDataValid() &&
          this.filterOutEmptyTweets(this.tweets).length > 0 &&
          this.areConditionalRetweetConditionsValid() &&
          this.areAutoplugConditionsValid() &&
          this.areAutoDMConditionsValid() &&
          !this.isGifUploading &&
          this.isLinkedInInfoValid() &&
          this.isFacebookInfoValid() &&
          this.areDelayBetweenTweetsConditionsValid() &&
          this.areShareOnInstagramConditionsValid()
        );
      },
      clearMediaSelectionAtIndex(tweet, i) {
        this.mediaToDelete[tweet.count] = lodash.isNil(this.mediaToDelete[tweet.count])
          ? []
          : this.mediaToDelete[tweet.count];
        this.mediaToDelete[tweet.count].push(tweet.mediaFile[i].name);
        tweet.mediaFile.splice(i, 1);
        tweet.fileList.splice(i, 1);
        this.$forceUpdate();
      },
      close() {
        this.$eventStore && this.$eventStore.closeComposerWithoutScheduling();
        ThreadMixin.methods.close.bind(this)();
        this.showTimeBox = false;
      },
      postNow() {
        const allowedToUseLinkedIn = this.isUserPlanPremiumOrAbove(this.userProfile.customerStatus);
        let text = '';
        if (this.linkedInInfo.text !== null && !allowedToUseLinkedIn && this.isUserConnectedToLinkedIn) {
          text =
            `Your LinkedIn post will not be published because you don't have a ${this.getPlanLabel(
              'premium'
            )} subscription.<br>` +
            + 'You can <a target="_blank" href="/billing"><u>upgrade your subscription</u></a> then come back here to post.<br><br>'
            + 'Do you still want to publish this tweet now?';
        } else if (this.linkedInInfo.text !== null && !this.isUserConnectedToLinkedIn) {
            if (this.facebookInfo.text !== null && !this.isUserConnectedToFacebookWithPage) {
              text = 'Your LinkedIn and Facebook posts will not be published because you didn\'t connect your'
                + ' LinkedIn and your Facebook page or group to Hypefury.<br>'
                + 'Please visit <a target="_blank" href="/settings"><u>the Settings page</u></a>'
                + ' to do that.<br><br>Do you still want to publish this tweet now?';
            } else {
                text = 'Your LinkedIn post will not be published because you didn\'t connect your'
                + ' LinkedIn to Hypefury.<br>'
                + 'Please visit <a target="_blank" href="/settings"><u>the Settings page</u></a>'
                + ' to do that.<br><br>Do you still want to publish this tweet now?';
            }
        } else if (
          this.facebookInfo.text !== null
          && !this.isUserConnectedToFacebookWithPage
          && !this.isAnyFacebookGroupSelected
        ) {
          text = 'Your Facebook post will not be published because you didn\'t connect your'
            + ' Facebook page or group to Hypefury.<br>'
            + 'Please visit <a target="_blank" href="/settings"><u>the Settings page</u></a>'
            + ' to do that.<br><br>Do you still want to publish this tweet now?';
        } else {
          text = 'Are you sure you want to post to Twitter now?';
        }
        this.swalModal({
          title: `Post Now`,
          html: text,
          type: 'question',
          showCancelButton: true,
          confirmButtonText: 'Yes, post now',
          preConfirm: () => {
            const postNow = true;
            this.scheduleThread(postNow);
          }
        });
      },
      processSave() {
        if (this.isCurrentPageDrafts) {
          this.saveAsADraft();
          return;
        }
        if (this.isCategoryPost) {
          this.saveAsRecurrentPost();
          return;
        }
        if (this.timeFromParent) {
          this.scheduleThread(false);
          return;
        }
        this.addToQueue();
      },
      initialState() {
        return {
          wasSubmitted: false,
          startedWriting: false,
          displayInspiration: false,
          time: null,
          showLinkedInBlock: false,
          showFacebookBlock: false,
          delayForRetweet: '12 hours',
          minFavoritesThreshold: null,
          minRetweetsThreshold: null,
          tweets: getUnfinishedPost() ? getUnfinishedPost().tweets : [this.emptyTweet(0)],
          files: {},
          submitting: false,
          isFavorite: getUnfinishedPost() ? getUnfinishedPost().isFavorite : false,
          linkedInText: '',
          linkedInIsPostPublic: true,
          updateLinkedInInfoFromParentToChildFunction: null,
          selectedCategories: [],
          facebookText: '',
          updateFacebookInfoFromParentToChildFunction: null,
          isSelectingGifForTweetIndex: null,
          autoDMText: '',
          isAutoDMEnabled: false,
          mediaToDelete: [],
        };
      },
      replaceTweetsWithInspirationTweet(tweetStatus) {
        if (this.isThreadEmpty === true) {
          this.tweets = [{
            count: 0,
            media: [],
            fileList: [],
            mediaFile: [],
            published: false,
            status: tweetStatus,
            guid: uuidv1(),
          }]
        }
      },
      scheduleThread: function (postNow) {
        let tweets = this.filterOutEmptyTweets(this.tweets);

        const user = this.useLocalStorage ? 'temp_user' : this.userProfile.uid;
        const thread = Thread.newThread({
          time: moment.tz(this.time, this.timezone).toDate(),
          tweets,
          user,
          isFavorite: this.isFavorite,
          postNow,
          conditionalRetweetsConditions: this.getConditionalRetweetsConditions(),
          autoplug: this.getAutoplugConditions(),
          shareOnInstagram: this.shareOnInstagram,
          linkedIn: this.isLinkedInEnabled ? this.linkedInInfo : null,
          facebook: lodash.get(this.facebookInfo, 'text') ? this.facebookInfo : null,
          delayBetweenTweets: this.saveAndGetDelayBetweenTweets(),
          categories: this.categories,
          instagramCaption: this.instagramCaption,
          autoDM: this.getAutoDMConditions()
        });

        if (this.useLocalStorage) {
          this.saveThreadToLocalStorage(thread);
          this.wasSubmitted = true;
          setTimeout(() => {
            this.close();
            this.$emit('reopen-new-thread-modal', { time: this.time, isFocusModeEnabled: this.isFocusModeEnabled });
            removeUnfinishedPost();
            this.resetModal();
            this.wasSubmitted = false;
          }, 1500);
        } else {
          return this.prepareThreadForSaving(thread, false);
        }
      },
      saveAsADraft: function () {
        let tweets = this.filterOutEmptyTweets(this.tweets);

        const thread = new DraftThread({
          tweets,
          user: this.userProfile.uid,
          isFavorite: this.isFavorite,
          conditionalRetweetsConditions: this.getConditionalRetweetsConditions(),
          autoplug: this.getAutoplugConditions(),
          shareOnInstagram: this.shareOnInstagram,
          linkedIn: this.isLinkedInEnabled ? this.linkedInInfo : null,
          facebook: lodash.get(this.facebookInfo, 'text') ? this.facebookInfo : null,
          delayBetweenTweets: this.saveAndGetDelayBetweenTweets(),
          categories: this.categories,
          instagramCaption: this.instagramCaption,
          autoDM: this.getAutoDMConditions()
        });
        return this.prepareThreadForSaving(thread, true);
      },
      async replaceRecurrentPost() {
        const removeMediaFromThread = () => {
          this.mediaToDelete.map((mediaArray, i) => {
            mediaArray.forEach((mediaName) => {
              if (this.tweets[i]) {
                this.tweets[i].media = this.tweets[i].media.map((media) => {
                  return media && media.name === mediaName ? undefined : media;
                });
              }
            });
          });
        };

        const updatedTweets = this.tweets.map((tweet) =>
          lodash.omit(tweet, [
            'userProfileClicks',
            'retweetCount',
            'replyCount',
            'quoteCount',
            'favoriteCount',
            'impressions',
          ])
        );

        const thread = new DraftThread({
          tweets: updatedTweets,
          user: this.userProfile.uid,
          isFavorite: this.isFavorite,
          source: 'hypefury-app',
          categories: this.categories,
          isRecurrentPost: true,
          postIdToReplace: this.selectedRecurrentPost.id,
        });

        try {
          removeMediaFromThread();

          await this.prepareThreadForSaving(thread, true);
        } catch {
          this.swalModal({
            title: 'Sorry',
            text: `An error has occurred while replacing your post.`,
            type: 'warning',
          });
        }
      },
      saveAsRecurrentPost: function() {
        if (!this.categories || this.categories.length === 0) {
          return;
        }
        const thread = new DraftThread({
          tweets: this.tweets,
          user: this.userProfile.uid,
          isFavorite: this.isFavorite,
          source: 'hypefury-app',
          categories: this.categories,
          isRecurrentPost: true,
        });

        return this.prepareThreadForSaving(thread, true);
      },
      prepareThreadForSaving(thread, saveAsADraft) {
        const submitCheck = thread.postNow ? this.canFormBeSubmittedIgnoreTime : this.canFormBeSubmitted;
        const canFormNotBeSubmitted = !saveAsADraft && !submitCheck() ||
          saveAsADraft && !this.canFormBeSubmittedAsADraft();
        if (canFormNotBeSubmitted) return;
        this.submitting = true;
        setUnfinishedPost(this.tweets, this.isFavorite, this.shareOnInstagram);
        return this.postThread(thread, this);
      },
      postThread: (thread, that) => {
        const uploadAllMedia = () => Promise.all(thread.tweets.map(tweet => {
          return that.uploadMedia(tweet);
        }));

        return uploadAllMedia()
          .then(tweets => {
            thread.tweets = tweets;
            return saveThread(thread);
          })
          .catch(error => {
            console.error(error);
            alert('An error has occurred while uploading the media.')
          });

        function saveThread(thread) {
          return thread.saveToFirestore(that.currentUser, that.userProfile.uid, that.timezone, that.$eventStore)
            .then(result => {
              that.wasSubmitted = true;
              return result
            })
            .then(result => new Promise(resolve => setTimeout(() => resolve(result), 1000)))
            .then((result) => {
              that.wasSubmitted = false;
              that.close();
              const threadOrTweet = thread.tweets.length > 1 ? 'Thread' : 'Tweet';
              const isDraft = thread instanceof DraftThread;

              let message = '';

              if (thread.postNow) {
                message = `${threadOrTweet} on its way!`;
              } else if (isDraft) {
                message = `${thread.isRecurrentPost ? 'Recurrent post' : 'Draft'} saved.`;
              } else {
                message = `${threadOrTweet} successfully scheduled!`;
              }

              if (that.isAutoDMEnabled && that.doesUserNotHaveDMPermission) {
                that.swalModal({
                  title: `Auto-DM Permission`,
                  html: that.noAutoDMPermissionsPopUpMessage,
                  type: 'question',
                  showCancelButton: true,
                  confirmButtonText: 'Logout',
                  preConfirm: () => that.logout(),
                });
              }

              that.$notify({ type: 'success', message });
              thread.id = result.threadId;

              removeUnfinishedPost();
              that.resetModal();
              that.inspirationTweetComponentKey += 1;
              if (that.$refs.tabsModal) {
                that.$refs.tabsModal.selectTab(0);
              }

              return thread;
            })
            .catch(error => {
              console.error(error);
              alert('An error has occurred while saving the thread.')
            });
        }
      },
      setTime: function() {
        // Done here instead of in data() because this.userProfile.timezone is needed
        this.time = this.timeFromParent ? this.formatTimeForInput(this.timeFromParent) : this.now();
      },
      onInputChange() {
        setUnfinishedPost(this.tweets, this.isFavorite, this.shareOnInstagram);
        this.startedWriting = true;
      },
      watchWriting() {
        const that = this;

        if (!that.showInspirationTweets) return;

        function displayInspirationIfDidntType() {
          setTimeout(() => {
            if (that.startedWriting) return;
            that.displayInspiration = true;
          }, 3000);
        }

        function displayInspirationIfTypedTooLittle() {
          setTimeout(() => {
            if (that.tweets[0].status.length <= 20)
            that.displayInspiration = true;
          }, 10000);
        }

        switch (this.userProfile.settings.inspirationTweetsType) {
          case 'never':
            break;
          case 'always':
            that.displayInspiration = true;
            break;
          default:
            displayInspirationIfDidntType();
            displayInspirationIfTypedTooLittle();
            break;
        }
      },
      toggleFocusMode: function () {
        setTimeout(() => {
          this.isFocusModeEnabled = !this.isFocusModeEnabled;
        }, 5);
      },
      openGifSelector(index) {
        this.isSelectingGifForTweetIndex = index;
      },
      closeGifSelector() {
        const index = this.isSelectingGifForTweetIndex;
        this.isSelectingGifForTweetIndex = null;
        this.$forceNextTick(() => {
          const element = this.$refs[`compose-tweet-${index}`][0].$refs.tweet;
          if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
            element.focus();
          }
        })
      },
    },
    mounted() {
      this.setTime();
      this.$nextTick(function() {
        if (this.categoryId) {
          this.categories.push(fb.categoriesCollection.doc(this.categoryId));
        }
      });
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      ...mapState([
        'schedule',
        'isWholeScheduleLoaded',
        'userCategories',
      ]),
      categories() {
        return this.selectedCategories.map((categoryId) => {
          return categoryId;
        });
      },
      isThreadEmpty() {
        return this.filterOutEmptyTweets(this.tweets).length === 0;
      },
    },
    mixins: [
      ThreadMixin,
      TaggingMixin,
      UploadContainerMixin,
      LinkedInMixin,
      SwalModalMixin,
      FacebookMixin,
      MobileMixin,
      AutoDMMixinVue,
      LogoutMixinVue,
      QuoteTweetMixin,
      CustomerStatusMixin,
    ],
  }
</script>

<style>
  .hwt-backdrop {
    display: none;
  }
  @supports(padding: max(0px)) {
    form {
      padding-bottom: env(safe-area-inset-bottom)!important;
    }
  }
</style>
