<template>
  <modal :show="show" @close="close">
    <div slot="header" class="modal-title">
      Assign Categories to Your Post
    </div>
    <div>
      <div class="flex flex-wrap">
        <div data-cy="category-item" class="custom-tags" v-for="category in userCategories" :key="category.id">
          <label>
            <input type="checkbox" :value="category.id" v-model="selectedCategories" />
            <span>{{ category.name }}</span>
          </label>
        </div>
      </div>
      <new-category-mini-form class="mt-3" @new-category-added="autoSelectNewCategory" />
      <div class="flex justify-end mt-3">
        <button
          data-cy="edit-categories-save"
          class="button primary-button small-button"
          :disabled="isSaving"
          @click="saveCategories">
          <span v-if="isSaving">Saving..</span>
          <span v-else>Save Categories</span>
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
  import NewCategoryMiniForm from "../../components/NewCategoryMiniForm"
  import { mapState } from 'vuex';
  import dao from '@/dao';
  import InspirationTweetsMixin from '../Mixins/InspirationTweetsMixin.vue';

  const fb = require('../../firebase');

  export default {
    props: {
      post: Object,
      show: Boolean,
      isFromInspirationTweet: Boolean,
    },
    components: {
      NewCategoryMiniForm
    },
    computed: {
      ...mapState(['userCategories']),
    },
    data() {
      return {
        selectedCategories: [],
        isSaving: false,
      };
    },
    watch: {
      post: {
        immediate: true,
        handler: function (newVal) {
          this.selectedCategories = [];
          if (newVal) {
            if (newVal.categories) {
                newVal.categories.forEach((doc) => {
                this.selectedCategories.push(doc.id);
              });
            } else if (newVal.categoriesIds) {
              this.selectedCategories = newVal.categoriesIds;
            }
          }
        },
      },
    },
    methods: {
      close(event) {
        this.$emit('close', event);
      },
      autoSelectNewCategory(categoryId) {
        this.selectedCategories.push(categoryId);
      },
      saveCategories() {
        this.isSaving = true;
        const categoriesToSave = this.selectedCategories.map((categoryId) => {
          return fb.categoriesCollection.doc(categoryId);
        });
        dao.posts
          .assignCategoriesToPost(this.post, categoriesToSave)
          .then(() => {
            if (this.isFromInspirationTweet) {
              this.$eventStore.inspirationTweets.updateTweetCategories();
              this.$emit('update-current-inspiration-tweet', { ...this.post, categoriesIds: this.selectedCategories });
            }

            this.updateInspirationTweet(this.post.id, { categoriesIds: this.selectedCategories });

            this.isSaving = false;
            this.close();
            this.$notify({
              type: 'success',
              message: 'Categories have been updated successfully.',
            });
          })
          .catch((error) => {
            console.log(error);
            this.isSaving = false;
            this.$notify({ type: 'error', message: 'Categories could not be updated.' });
          });
      },
    },
    mixins: [InspirationTweetsMixin],
  };
</script>

<style scoped>
  .modal {
    z-index: 21;
  }
</style>
