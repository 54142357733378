<template>
  <div>
    <div class="row">
      <div class="flex items-center justify-between">
        <div class="flex items-center font-medium leading-none">
          <span>Enable Tweet Booster</span>
          <info-tooltip
            content="Do you want to give this tweet a little boost a few hours after it is posted?
            Or do want to share it with your followers from the other side of the planet?"
          ></info-tooltip>
        </div>
        <div class="flex space-x-2">
          <div
            class="border rounded-full border-divider-darker px-2 text-secondary cursor-pointer text-xs pt-px hover:border-divider hover:text-muted"
            v-on:click="onClickEditConditionalRetweet"
            v-show="conditionalRetweetEnabled"
            v-if="!isNewComposer"
          >
            <span v-if="!showConditionalRetweetBlock && conditionalRetweetEnabled">Edit</span>
            <span v-else-if="showConditionalRetweetBlock && conditionalRetweetEnabled">Hide</span>
          </div>
          <base-switch
            class="align-middle"
            v-model="conditionalRetweetEnabled"
            @input="updateEnabled"
          ></base-switch>
        </div>
      </div>
    </div>
    <div class="mt-3 space-y-2" v-if="(showConditionalRetweetBlock || isNewComposer) && conditionalRetweetEnabled">
      <div class="form-group lg:flex lg:items-center lg:justify-between">
        <label for="delayForRetweet" class="form-control-label">Delay</label>
        <div class="relative mt-2 lg:mt-0 lg:w-1/2">
          <select
            data-cy="retweet-delay"
            class="form-control"
            data-toggle="select"
            id="delayForRetweet"
            v-model="delayForRetweet" @change="updateParentValues()">
            <option v-for="option in delayForRetweetOptions"
              :key="option"
              :value="option">
              {{ option }}
            </option>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-primary"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="fill-current h-4 w-4"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path></svg></div>
          </select>
        </div>
      </div>
      <div class="form-group lg:flex lg:items-center lg:justify-between">
        <label for="conditionForRetweet" class="flex items-center form-control-label text-dark-mode-15">
          Delay Until
          <info-tooltip
            content="Choose whether to delay your retweet until this tweet reaches a minimum number of likes or retweets."
          ></info-tooltip>
        </label>
        <div class="relative mt-2 lg:mt-0 lg:w-1/2 flex items-center space-x-2">
          <div class="w-1/2">
            <base-input
              v-if="conditionForRetweet === 'Retweets'"
              type="number"
              data-cy="retweet-min-retweets"
              min="1"
              v-model="minRetweetsThreshold"
              @input="updateParentValues()"
              name="minRetweetsThreshold"
            ></base-input>
            <base-input
              v-else
              type="number"
              data-cy="retweet-min-likes"
              min="1"
              v-model="minFavoritesThreshold"
              @input="updateParentValues()"
              name="minFavoritesThreshold"
            ></base-input>
          </div>
          <div class="relative w-1/2">
            <select
              data-cy="retweet-condition-select"
              class="form-control"
              data-toggle="select"
              id="conditionForRetweet"
              v-model="conditionForRetweet"
              @change="updateParentValues()"
            >
              <option v-for="option in conditionForRetweetOptions"
                :key="option"
                :value="option">
                {{ option }}
              </option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-primary"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="fill-current h-4 w-4"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path></svg></div>
          </div>
        </div>
      </div>

      <div v-if="isNewComposer" class="flex justify-end">
        <button class="btn text primary" :disabled="!canSaveConditionalRetweetSettings" @click="updateParentValues(true)">
          Save
        </button>
      </div>
    </div>
  </div>
</template>
<script>
  import lodash from 'lodash';
  import { mapGetters } from 'vuex';
  export default {
    props: {
      thread: Object,
      value: {
        type: Boolean,
        default: false,
      },
      isNewComposer: {
        type: Boolean,
        default: false,
      },
    },
    watch: {
      // We need this so that if the data changes after the component is mounted, we can update the value
      'userProfile.conditionalRetweetsConditions'(val, oldVal) {
        if (!this.thread && (val !== oldVal)) {
          this.updateValuesFromUserProfile(this.userProfile);
        }
      },
      value(val) {
        this.conditionalRetweetEnabled = val;
      },
      thread: function (val) {
        this.updateValuesFromThread(val);
      },
      conditionalRetweetEnabled: function (newVal, oldVal) {
        this.updateEnabled();
        if (!this.hasModalJustOpened) {
          this.hasModalJustOpened = true;
          return;
        }
        if (!oldVal && newVal) {
          this.showConditionalRetweetBlock = true;
        }
      },
      minFavoritesThreshold(newVal) {
        //Ensure that the value is at least 1 without modifying any preexisting values
        if (newVal <= 0) {
          this.minFavoritesThreshold = 1;
        }
      },
      minRetweetsThreshold(newVal) {
        //Ensure that the value is at least 1 without modifying any preexisting values
        if (newVal <= 0) {
          this.minRetweetsThreshold = 1;
        }
      },
    },
    data() {
      return {
        minFavoritesThreshold: 1,
        minRetweetsThreshold: 1,
        showConditionalRetweetBlock: false,
        conditionalRetweetEnabled: false,
        delayForRetweet: '12 hours',
        delayForRetweetOptions: [
          '30 minutes',
          '1 hour',
          '3 hours',
          '6 hours',
          '9 hours',
          '12 hours',
          '18 hours',
          '24 hours',
          '36 hours',
          '48 hours',
        ],
        conditionForRetweet: null,
        conditionForRetweetOptions: ['Retweets', 'Likes'],
        hasModalJustOpened: false,
      };
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      canSaveConditionalRetweetSettings() {
        return (
          (!lodash.isNil(this.minFavoritesThreshold) && Number(this.minFavoritesThreshold) >= 1) ||
          (!lodash.isNil(this.minRetweetsThreshold) && Number(this.minRetweetsThreshold) >= 1)
        );
      },
    },
    mounted() {
      if (!this.thread || !this.thread.conditionalRetweetsConditions) {
        this.updateValuesFromUserProfile(this.userProfile);
      }

      if (!this.conditionalRetweetEnabled) {
        this.hasModalJustOpened = true;
      }
      this.updateConditionForRetweet();
      this.updateParentValues();

      this.$emit(
        'updateConditionalRetweetValuesFromParentToChildFunction',
        (delayForRetweet, minRetweetsThreshold, minFavoritesThreshold, conditionForRetweet) => {
          this.delayForRetweet = delayForRetweet || this.delayForRetweet;
          this.minRetweetsThreshold = Number(minRetweetsThreshold) || this.minRetweetsThreshold;
          this.minFavoritesThreshold = Number(minFavoritesThreshold) || this.minFavoritesThreshold;
          this.conditionForRetweet = conditionForRetweet || this.conditionForRetweet;
        }
      );
    },
    methods: {
      onClickEditConditionalRetweet() {
        this.showConditionalRetweetBlock = !this.showConditionalRetweetBlock;
      },
      updateConditionForRetweet() {
        if (!lodash.isNil(this.minRetweetsThreshold)) {
          this.conditionForRetweet = 'Retweets';
        } else if (!lodash.isNil(this.minFavoritesThreshold)) {
          this.conditionForRetweet = 'Likes';
        } else {
          this.conditionForRetweet = 'Likes';
        }
      },
      updateValuesFromUserProfile(userProfile) {
        if (!userProfile.conditionalRetweetsConditions) {
          this.showConditionalRetweetBlock = false;
          this.conditionalRetweetEnabled = false;
          return;
        }

        const delayForRetweet = userProfile.conditionalRetweetsConditions.delayForRetweet;
        // If delayForRetweet has not been set for the user yet, use the default value from data()
        if (delayForRetweet) {
          this.delayForRetweet = delayForRetweet;
        }

        this.conditionalRetweetEnabled =
          userProfile.conditionalRetweetsConditions.showConditionalRetweetBlock ||
          this.conditionalRetweetEnabled;
        this.minFavoritesThreshold =
          userProfile.conditionalRetweetsConditions.minFavoritesThreshold ||
          this.minFavoritesThreshold;
        this.minRetweetsThreshold =
          userProfile.conditionalRetweetsConditions.minRetweetsThreshold ||
          this.minRetweetsThreshold;
        this.updateParentValues();
        this.updateEnabled();
      },
      updateParentValues(isSaving) {
        if (this.isNewComposer && !isSaving) {
          return;
        }
        this.resetConditionalRetweetsMinRTAndMinLikesIfEmpty();
        this.$emit('values-set',
          this.delayForRetweet,
          this.conditionForRetweet === 'Likes' ? null : this.minRetweetsThreshold,
          this.conditionForRetweet === 'Likes' ? this.minFavoritesThreshold : null,
          this.conditionForRetweet);
      },
      updateValuesFromThread(thread) {
        if (! thread) {
          return;
        }
        // Always start with options hidden
        this.showConditionalRetweetBlock = false;
        if (thread.conditionalRetweetsConditions === null) {
          this.conditionalRetweetEnabled = false;
        } else {
          this.conditionalRetweetEnabled = true;
          const delayForRetweet = thread.conditionalRetweetsConditions.delayForRetweet;
          if (delayForRetweet) {
            if (isNaN(delayForRetweet)) {
              this.delayForRetweet = delayForRetweet;
            } else {
              this.delayForRetweet =  `${delayForRetweet} hour${delayForRetweet > 1 ? 's' : ''}`;
            }
          }
          this.minRetweetsThreshold = thread.conditionalRetweetsConditions.minRetweetsThreshold;
          this.minFavoritesThreshold = thread.conditionalRetweetsConditions.minFavoritesThreshold;
          this.updateConditionForRetweet();
          this.updateParentValues()
          this.updateEnabled()
        }
      },
      updateEnabled() {
        this.$emit('input', this.conditionalRetweetEnabled);
      },
      resetConditionalRetweetsMinRTAndMinLikesIfEmpty() {
        if (!this.minRetweetsThreshold) this.minRetweetsThreshold = 1;
        if (!this.minFavoritesThreshold) this.minFavoritesThreshold = 1;
      },
    },
  };
</script>
