<script>
  import lodash from 'lodash';
  import { mapGetters } from 'vuex';
  import { hypefuryAccountTwitterId } from '@/config';
  import moment from 'moment';
  import CustomerStatusMixin from './CustomerStatusMixin.vue';

  export default {
    mixins: [CustomerStatusMixin],
    computed: {
      ...mapGetters({ twitterUserInfo: 'getTwitterUserInfo', userProfile: 'getUserProfile' }),
      doesUserNotHaveDMPermission() {
        const accessLevel = lodash.get(this.twitterUserInfo, 'accessLevel');
        return accessLevel && accessLevel !== 'read-write-directmessages';
      },
      userRanOutOfDailyDMs() {
        const { successfulDMs, failedDMs } = lodash.get(this.userProfile, 'autoDMDailyCounter', {
          successfulDMs: 0,
          failedDMs: 0,
          updatedAt: null,
        });

        const didUserRunOutOfDailyDMs =
          successfulDMs >= this.autoDMLimits.successfulDMs ||
          failedDMs >= this.autoDMLimits.failedDMs;

        return this.autoDMLimits.successfulDMs > 0 && didUserRunOutOfDailyDMs;
      },
      freeAutoDMsDaysLeft() {
        const updatedAt = lodash.get(this.userProfile, 'freeWeeklyAutoDMs.updatedAt');
        if (!updatedAt) return null;
        const daysLeft = moment(updatedAt.toDate()).diff(moment().subtract(1, 'week'), 'days');
        const daysLeftText = (() => {
          if (daysLeft === 0) return 'less than a day';
          if (daysLeft === 1) return '1 day';
          if (daysLeft > 1) return `${daysLeft} days`;
        })();
        return daysLeftText;
      },
      autoDMLimits() {
        const yannickAccountTwitterId = '95882140';
        const extendedLimitUsers = [yannickAccountTwitterId, hypefuryAccountTwitterId];
        if (extendedLimitUsers.includes(this.userProfile.twitterId)) {
          return { successfulDMs: 500, failedDMs: 500 };
        } else {
          const autoDMLimits = this.PLANS[this.userProfile.customerStatus].limits.dailyAutoDMs;
          return { successfulDMs: autoDMLimits, failedDMs: autoDMLimits };
        }
      },
    },
    methods: {
      addTag(tag) {
        this.selectedTags = [...this.selectedTags, tag];
      },
      addNextDMTimeToCampaigns(campaigns) {
        const validCampaigns = campaigns.filter((campaign) => {
          const { autoDM, scheduled, time, deleted, publishingError, isDeletedFromTwitter } =
            campaign;
          const isStillActive = moment(time.toDate()).add(3, 'days').isAfter(moment());

          return (
            lodash.get(autoDM, 'enabled') &&
            !lodash.get(autoDM, 'isDisabled') &&
            scheduled &&
            isStillActive &&
            !deleted &&
            !publishingError &&
            !isDeletedFromTwitter
          );
        });

        let campaignsOrder = [];

        // Prioritize posts without lastRanAt
        const postsWithoutLastRanAt = validCampaigns.filter((post) => !post.autoDM.lastRanAt);
        if (postsWithoutLastRanAt.length) {
          campaignsOrder.push(
            ...lodash.orderBy(postsWithoutLastRanAt, (post) => moment(post.time).toDate(), ['asc'])
          );
        }

        const postsWithLastRanAt = validCampaigns.filter((post) => post.autoDM.lastRanAt);
        campaignsOrder.push(
          ...lodash.orderBy(
            postsWithLastRanAt,
            (post) => moment(post.autoDM.lastRanAt.toDate()).toDate(),
            ['asc']
          )
        );

        // Premium: we take all the posts
        // Standard: we take all the free posts + the number of free posts the user has left
        // Others: we take all the free posts
        let finalCampaignsOrder = [];
        if (this.userProfile.customerStatus === 'premium') {
          finalCampaignsOrder = campaignsOrder;
        } else if (this.userProfile.customerStatus === 'standard') {
          let userFreeAutoDMsLeft = lodash.get(this.userProfile, 'freeWeeklyAutoDMs.value', 0);
          const userFreeAutoDMsResetAt = lodash.get(
            this.userProfile,
            'freeWeeklyAutoDMs.updatedAt'
          );
          finalCampaignsOrder = campaignsOrder.filter((campaign) => {
            const wasCampaignPostedAfterReset =
              userFreeAutoDMsResetAt &&
              moment(campaign.time.toDate()).isAfter(moment(userFreeAutoDMsResetAt.toDate()));

            if (campaign.autoDM.isFree) {
              return true;
            } else if (userFreeAutoDMsLeft > 0 && wasCampaignPostedAfterReset) {
              userFreeAutoDMsLeft--;
              return true;
            }
            return false;
          });
        }

        return campaigns.map((campaign) => {
          const postOrder = lodash.findIndex(finalCampaignsOrder, ['id', campaign.id]);

          let nextDMTime = null;
          if (postOrder >= 0) {
            // We first need to know when the next cron execution will be so we can calculate the next DM time
            const currentMinute = moment().format('mm');
            const nextCronExecution =
              currentMinute < 30
                ? moment().startOf('hour').add(30, 'minutes')
                : moment().startOf('hour').add(1, 'hour');

            nextDMTime = nextCronExecution.add(postOrder * 30, 'minutes').toDate();
          }

          return {
            ...campaign,
            nextDMTime,
          };
        });
      },
    },
    data() {
      return {
        selectedTags: [],
        noAutoDMPermissionsPopUpMessage: `This tweet is set to send auto-DMs, but Hypefury doesn't have the permission
to send DMs on your behalf.<br>Please logout, then log back in order to refresh your permissions.`,
      };
    },
  };
</script>
