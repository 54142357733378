<template>
  <div class="tweet">
    <div
      id="tweet-container"
      ref="tweet-container"
      @drop="uploadSectionDrop(tweet, $event)"
      @dragover="uploadSectionDragOver(tweet, $event)"
      @dragleave="uploadSectionDragLeave(tweet, $event)"
    >
      <base-input ref="textarea-container" inputGroupClasses="textarea-container" alternative>
        <div class="tweet-container" ref="tweet_container">
          <vue-tribute :key="key" :options="tributeOptions" class="thread-textarea">
            <textarea
              data-cy="tweet-input"
              rows="3"
              dir="auto"
              class="form-control simple"
              ref="tweet"
              @paste="onPaste"
              v-model="status"
              @input="updateStatus"
              :placeholder="statusPlaceholder"
              :data-tweet-count="tweet.count"
              :data-gramm="shouldEnableGrammarly"
              :data-gramm_editor="shouldEnableGrammarly"
              :data-enable-grammarly="shouldEnableGrammarly"
              @focus="watchWriting(); shouldEnableGrammarly = true"
              @keyup.ctrl.enter="pressedSave"
              @keydown.meta.enter="pressedSave"
              @blur="onBlur"
              v-click-outside="onClickOutside"
            >
            </textarea>
          </vue-tribute>
        </div>

        <div class="tweet-container border-l-2 my-2 mx-2 pl-4" v-if="quoteTweetPreview">
          <div class="flex justify-between items-center">
            <div class="flex space-x-2 items-center">
              <img :src="quoteTweetPreview.userProfilePictureURL" class="rounded-full h-6 w-6" />
              <span>{{ quoteTweetPreview.userDisplayName }}</span>
              <span class="text-muted">@{{ quoteTweetPreview.username }}</span>
            </div>

            <base-button
              type="secondary"
              class="btn icon secondary small"
              @click="removeQuoteTweet"
            >
              <inline-svg src="/img/icons/close.svg" />
            </base-button>
          </div>
          <div
            class="whitespace-pre-wrap break-words"
            v-html="formatTweet(quoteTweetPreview.text)"
            dir="auto"
          />
        </div>

        <div class="tweet-container border-l-2 my-2 mx-2 px-4" v-if="isThreadFinisherTweet">
          <div class="flex space-x-2 items-center">
            <img :src="userProfile.photoURL" class="rounded-full h-6 w-6" />
            <span>{{ userProfile.name }}</span>
            <span class="text-muted">@{{ userProfile.username }}</span>
          </div>
          <div
            class="whitespace-pre-wrap break-words"
            v-html="formatTweet(firstTweetsText)"
            dir="auto"
          />
        </div>

        <div v-if="isPoll" class="poll-container">
          <div v-for="(pollOption, i) in pollOptions" :key="i" class="poll-question">
            <input
              :placeholder="`Choice ${i + 1}${i > 1 ? ' (optional)' : ''}`"
              v-model="pollOptions[i]"
              maxlength="25"
            />
            <div class="poll-question-options space-x-1">
              <div v-if="pollOption" class="text-muted text-xs">
                {{ pollOption.length }}/25
              </div>
              <button
                v-if="i === pollOptions.length - 1 && canAddPollOption"
                class="poll-question-cta outline-none focus:outline-none"
                @click="addPollOption"
              >
                <!-- cross.svg -->
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M19 11h-6V5a1 1 0 0 0-2 0v6H5a1 1 0 0 0 0 2h6v6a1 1 0 0 0 2 0v-6h6a1 1 0 0 0 0-2z" data-name="plus"></path>
                </svg>
              </button>
            </div>
          </div>
          <div class="poll-length">
            <button
              @click.prevent="togglePollLength"
              class="flex items-center justify-between p-3 w-full bg-transparent rounded-b hover:bg-transparent focus:bg-transparent focus:outline-none text-muted">
              <div class="text-xs">Poll Length</div>
              <div class="flex items-center space-x-3">
                <div class="text-xs">
                  <span v-if="pollDuration.days > 0">{{pollDuration.days}}d</span>
                  <span v-if="pollDuration.hours > 0">&nbsp;{{pollDuration.hours}}h</span>
                  <span v-if="pollDuration.minutes > 0">&nbsp;{{pollDuration.minutes}}m</span>
                </div>
                <!-- arrow-down.svg -->
                <svg
                  class="fill-current h-4 w-4"
                  :class="{ 'transform rotate-180': isEditingPollLength }"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </button>
            <div
              v-if="isEditingPollLength"
              class="flex items-center space-x-2 p-3 pt-0">
              <label class="w-full space-y-1">
                <div class="text-xs text-muted">Days</div>
                <div class="relative">
                  <select v-model="pollDuration.days" class="form-control">
                    <option v-for="i in Array.from(Array(8).keys())" :value="i" :key="i">{{i}}</option>
                  </select>
                  <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-primary">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="fill-current h-4 w-4"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path></svg>
                  </div>
                </div>
              </label>
              <label class="w-full space-y-1">
                <div class="text-xs text-muted">Hours</div>
                <div class="relative">
                  <select v-model="pollDuration.hours" class="form-control" :disabled="pollDuration.days === 7">
                    <option v-for="i in Array.from(Array(24).keys())" :value="i" :key="i">{{i}}</option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-primary"
                    :class="{'text-muted': pollDuration.days === 7}"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="fill-current h-4 w-4"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path></svg>
                  </div>
                </div>
              </label>
              <label class="w-full space-y-1">
                <div class="text-xs text-muted">Minutes</div>
                <div class="relative">
                  <select v-model="pollDuration.minutes" class="form-control" :disabled="pollDuration.days === 7">
                    <option v-for="i in pollDurationMinutesOptions" :value="i" :key="i">{{i}}</option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-primary"
                    :class="{['text-muted']: pollDuration.days === 7}"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="fill-current h-4 w-4"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path></svg>
                  </div>
                </div>
              </label>
            </div>
          </div>
        </div>
        <portal-target
          :name="portalTargetName"
          multiple
          class="thread-preview space-y-2 p-2 pb-0 focus--hide"
        >
        </portal-target>

        <div class="thread-actions flex item-center p-1">
          <div class="flex items-center focus--hide">
            <tooltip data-cy="choose-emoji" content="Choose Emoji">
              <emoji-picker @selected-emoji="addEmoji($event)" />
            </tooltip>

            <tooltip data-cy="upload-images" :content="getUploadContainerTooltip(false)" v-if="showMediaButtons">
              <upload-container
                :pastedFiles="pastedFiles"
                :tweet="tweet"
                :portalTargetName="portalTargetName"
                :canAddMedia="canAddMedia"
                :showFacebookBlock="showFacebookBlock"
                :disabled="disableMediaUpload"
                :canAddMoreVideos="canAddMoreVideos"
                :clearMediaSelectionAtIndexParent="clearMediaSelectionAtIndex"
                :imageCountInThread="imageCountInThread"
                :showLinkedInBlock="showLinkedInBlock"
                @deleteReplacedMedia="deleteReplacedMedia"
              />
            </tooltip>

            <tooltip :content="getUploadContainerTooltip(true)" v-if="showMediaButtons">
              <div class="text-center" :class="{ disabled: isDisabledGifSelector }">
                <base-button data-cy="choose-gif" @click="openGifSelector(index)" class="btn icon small text-muted bg-transparent focus:bg-dark hover:bg-dark" :disabled="isDisabledGifSelector">
                  <!-- gif.svg -->
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <g fill="none" fill-rule="evenodd">
                      <path d="M0 0h24v24H0z"/>
                      <path fill="currentColor" fill-rule="nonzero" d="M19 5.001H5v1H3v-1c0-1.103.897-2 2-2h14c1.103 0 2 .897 2 2v1h-2v-1zm0 14H5v-1H3v1c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2v-1h-2v1zm-4-9v6h2v-3h3v-2h-3v-1h4v-2h-4c-1.103 0-2 .897-2 2zm-10 6h2c1.103 0 2-.897 2-2v-3H6v2h1v1H5v-4h4v-2H5c-1.103 0-2 .897-2 2v4c0 1.103.897 2 2 2zm5-2v2h4v-2h-1v-4h1v-2h-4v2h1v4h-1z"/>
                    </g>
                  </svg>
                </base-button>
              </div>
            </tooltip>

            <tooltip content="Generate a quote" :disabled="isGeneratingQuote" v-if="tweet.count === 0 && showMediaButtons">
              <base-button
                data-cy="generate-quote"
                type="primary"
                class="btn icon small text-muted bg-transparent focus:bg-dark hover:bg-dark"
                @click="fillWithGeneratedQuote(tweet)"
                :disabled="isGeneratingQuote"
              >
                <!-- loading.svg -->
                <svg v-if="isGeneratingQuote"
                  class="loading static"
                  xmlns="http://www.w3.org/2000/svg"  width="16" height="16" viewBox="0 0 16 16">
                  <g fill="currentColor">
                    <path opacity=".4" d="M2.9532779 14.20730183C-.4693762 11.42458165-.990022 6.37593198 1.79269817 2.9532779S9.62406802-.990022 13.0467221 1.79269817s3.9432999 7.83136985 1.16057973 11.25402393S6.37593198 16.990022 2.9532779 14.20730183zm8.83176368-10.8627782c-2.56718005-2.08719419-6.35332377-1.69674525-8.44051795.8704348s-1.69674525 6.35332376.8704348 8.44051794 6.35332376 1.69674525 8.44051794-.8704348 1.69674525-6.35332376-.8704348-8.44051794z"/>
                    <path d="M14.20730183 13.0467221l-1.55182546-1.26168052c2.08719419-2.56718005 1.69674525-6.35332377-.8704348-8.44051795l1.26168053-1.55182546c3.4226541 2.78272018 3.9432999 7.83136985 1.16057973 11.25402393z"/>
                  </g>
                </svg>
                <!-- quote.svg -->
                <svg
                  v-else
                  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.3909 7C13.3909 5.89543 14.2863 5 15.3909 5L19 5C20.1046 5 21 5.89543 21 7L21 12.4775C21 16.3012 19.1226 18.6912 15.3679 19.6476C14.3302 19.9119 13.3909 19.0626 13.3909 17.9918V17.9918C13.3909 17.026 14.1843 16.2947 15.0851 15.9464C16.4321 15.4257 17.1279 14.2693 17.1727 12.4775L15.3909 12.4775C14.2863 12.4775 13.3909 11.582 13.3909 10.4775L13.3909 7ZM3 7C3 5.89543 3.89543 5 5 5L8.60911 5C9.71368 5 10.6091 5.89543 10.6091 7L10.6091 12.4775C10.6091 16.3012 8.73175 18.6912 4.97702 19.6476C3.93933 19.9119 3 19.0626 3 17.9918V17.9918C3 17.026 3.79338 16.2947 4.6942 15.9464C6.04116 15.4257 6.73702 14.2693 6.78177 12.4775L5 12.4775C3.89543 12.4775 3 11.582 3 10.4775L3 7Z" fill="currentColor"/>
                </svg>
              </base-button>
            </tooltip>

            <tooltip
            :content="!isPoll ? 'Add Poll' : 'Remove Poll'">
              <base-button
                data-cy="add-poll"
                type="primary"
                class="btn icon small text-muted bg-transparent focus:bg-dark hover:bg-dark"
                :class="{ 'opacity-50': isPoll }"
                @click="togglePoll"
                :disabled="disablePollOption"
              >
                <!-- poll.svg -->
                <svg
                  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="3" y="5.5" width="5" height="2" rx="1" fill="currentColor"/>
                  <rect x="3" y="9.5" width="12" height="2" rx="1" fill="currentColor"/>
                  <path d="M3 14.5c0-.5523.44772-1 1-1h16c.5523 0 1 .4477 1 1s-.4477 1-1 1H4c-.55228 0-1-.4477-1-1Z" fill="currentColor"/>
                  <rect x="3" y="17.5" width="9" height="2" rx="1" fill="currentColor"/>
                </svg>
              </base-button>
            </tooltip>

            <div class="w-px h-6 mx-2 bg-divider"></div>

            <tooltip
              v-if="tweet.count === 0"
              :content="!isFavorite ? 'Set as an Evergreen Post' : 'Unset as an Evergreen Post'"
            >
              <base-button
                data-cy="compose-evergreen"
                class="btn icon small bg-transparent focus:bg-dark hover:bg-dark"
                :class="{ 'text-green-600': isFavorite, 'text-muted': !isFavorite }"
                @click="toggleFavorite"
                type=""
                size="sm"
              >
              <!-- star.svg -->
              <inline-svg src="/img/icons/star.svg" />
              </base-button>
            </tooltip>

            <div class="tweet-info-box hidden">
              <base-button class="tweets-counter" size="sm" type="secondary" v-if="canDeleteTweet">
                {{ tweet.count + 1 }} / {{ 2 }}
              </base-button>
            </div>
          </div>

          <div class="ml-auto flex items-center space-x-1">

            <div :class="charactersLeftClass" class="character-count">
              {{ charactersLeftCounter }}
            </div>

            <tooltip content="Remove Tweet" v-if="canDeleteTweet">
              <base-button
                type="secondary"
                data-cy="remove-tweet"
                class="btn icon secondary small"
                @click="deleteTweet(tweet)"
              >
                <!-- remove.svg -->
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z"></path>
                </svg>
              </base-button>
            </tooltip>

            <tooltip content="Add Tweet" v-show="showAddButton">
              <base-button
                type="secondary"
                data-cy="add-tweet"
                class="bg-main-color-100 text-white w-8 h-8 rounded-full outline-none focus:outline-none inline-flex items-center justify-center"
                @click="addTweetBox(tweet)"
              >
                <!-- plus.svg -->
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M19 11h-6V5a1 1 0 0 0-2 0v6H5a1 1 0 0 0 0 2h6v6a1 1 0 0 0 2 0v-6h6a1 1 0 0 0 0-2z" data-name="plus"></path>
                </svg>
              </base-button>
            </tooltip>
          </div>
        </div>
      </base-input>
    </div>
  </div>
</template>

<script>
  import controller from '@/controller';
  import { adjustTextAreaHeight } from '../../util/adjustTextAreaHeight';
  import TaggingMixin from '../../views/Mixins/TaggingMixin';
  import UploadContainerMixin from '../../views/Mixins/UploadContainerMixin';
  import EmojiPickerThreadMixin from '../../views/Mixins/EmojiPickerThreadMixin';
  import EmojiPicker from '../../components/Threads/EmojiPicker';
  import UploadContainer from '../../components/UploadContainer';
  import lodash from 'lodash';
  import CharactersLeftMixin from '../../views/Mixins/CharactersLeftMixin.vue';
  import { formatTweet } from '@/util/formatTweet';
  import QuoteTweetMixin from '../../views/Mixins/QuoteTweetMixin.vue';
  import { defaultThreadFinisherText } from '@/views/Composer/config';
  import dao from '@/dao';

  export default {
    name: 'compose-tweet',
    props: {
      index: {
        type: Number,
      },
      tweet: {
        type: Object,
      },
      focusOnTweetIndex: {
        type: Number
      },
      canAddMoreVideos: {
        type: Boolean,
      },
      canAddMediaToFacebook: {
        type: Boolean,
      },
      canAddMediaToLinkedIn: {
        type: Boolean,
      },
      canDeleteTweet: {
        type: Boolean,
      },
      show: {
        type: Boolean,
      },
      showFacebookBlock: {
        type: Boolean,
      },
      showLinkedInBlock: {
        type: Boolean,
      },
      isScheduleReply: {
        type: Boolean,
        default: false,
      },
      imageCountInThread: {
        type: Number,
        default: 0,
      },
      mentionedUserLength: {
        type: Number,
        default: 0,
      },
      showAddTweetButton: {
        type: Boolean,
        default: true,
      },
      threadIsFavorite: {
        type: Boolean,
        default: false,
      },
      showMediaButtons: {
        type: Boolean,
        default: true,
      },
      disableMediaUpload: {
        type: Boolean,
        default: false,
      },
      tweetHasPoll: {
        type: Boolean,
      },
      hasThreadFinisherTweet: {
        type: Boolean,
        default: false,
      },
      firstTweetsText: {
        type: String,
        default: '',
      },
      threadLength: {
        type: Number,
        default: 0,
      },
      disablePollOption: {
        type: Boolean,
        default: false,
      },
    },
    watch: {
      tweet: {
        deep: true,
        immediate: true,
        handler: function (newVal) {
          this.status = newVal.status;
        },
      },
      pollDuration: {
        deep: true,
        immediate: true,
        handler: function (value) {
          if (!this.tweet.poll || !value) return;
          // Minimum poll duration allowed is 5 minutes
          if (value.days === 0 && value.hours === 0) {
            this.pollDurationMinutesOptions = Array.from(Array(60).keys()).slice(5);
            if (value.minutes < 5) {
              this.pollDuration.minutes = 5;
            }
          } else {
            this.pollDurationMinutesOptions = Array.from(Array(60).keys());
          }

          // Maximum poll duration allowed is 7 days
          if (value.days === 7) {
            this.pollDuration.hours = 0;
            this.pollDuration.minutes = 0;
          }

          const poll = {
            duration: this.pollDuration,
            options: this.pollOptions,
          };
          this.$emit('update-poll', poll);
        }
      },
      pollOptions(value) {
        if (!this.tweet.poll) return;
        const pollOptions = value.filter((option, i) => {
          if (i === 0 || i === 1){
            return true;
          }
          return !lodash.isNil(option) && option.trim().length > 0
        });

        const poll = {
          duration: this.pollDuration,
          options: pollOptions,
        };
        this.$emit('update-poll', poll);
      },
      status: {
        immediate: true,
        handler: function () {
          this.$nextTick(function () {
            if (this.$refs.tweet) {
              this.previousHeight = adjustTextAreaHeight(this.$refs.tweet, this.$refs.tweet_container, this.previousHeight);
            }
          });
        }
      },
      show: function () {
        this.$nextTick(function () {
          if (this.$refs.tweet) {
            this.previousHeight = adjustTextAreaHeight(this.$refs.tweet, this.$refs.tweet_container, this.previousHeight);
          }
        });
        if (this.tweet.count == this.focusOnTweetIndex && this.$refs.tweet) {
          this.$nextTick(function() {
            this.$refs.tweet.focus();
          });
        }
      },
      focusOnTweetIndex: {
        immediate: true,
        handler: function (newVal) {
          if (this.tweet.count == newVal && this.$refs.tweet) {
            this.$nextTick(function() {
              this.$refs.tweet.focus();
            });
          }
        }
      },
      isThreadFinisherTweet: {
        immediate: true,
        handler: function (val) {
          if (val && !this.hasThreadFinisherTweet) {
            const savedThreadFinisherText = lodash.get(this.userProfile, 'settings.threadFinisherText');
            this.status = savedThreadFinisherText || defaultThreadFinisherText(this.userProfile.username);
            this.$nextTick(function () {
              this.$emit('status-updated', this.status);
              this.onInputChange(event, this.tweet.count);
            });
          }
        },
      },
    },
    mixins: [TaggingMixin, EmojiPickerThreadMixin, UploadContainerMixin, CharactersLeftMixin, QuoteTweetMixin],
    components: {
      EmojiPicker,
      UploadContainer,
    },
    data() {
      return {
        isGeneratingQuote: false,
        status: '',
        isFavorite: false,
        previousHeight: 79,
        pastedFiles: [],
        portalTargetName: Date.now().toString(),
        shouldEnableGrammarly: false,
        key: Date.now().toString(),
        isPoll: false,
        isEditingPollLength: false,
        pollOptions: [],
        pollDuration: null,
        pollDurationMinutesOptions: Array.from(Array(60).keys()),
      };
    },
    computed: {
      canAddMedia() {
        if (!this.tweet.mediaFile) return;

        if (
          (this.showFacebookBlock && !this.canAddMediaToFacebook) ||
          (this.showLinkedInBlock && !this.canAddMediaToLinkedIn) ||
          this.isPoll
        ) {
          return false;
        }

        return (
          this.tweet.mediaFile.length === 0 ||
          (this.tweet.mediaFile.length < 4 &&
            !this.tweet.mediaFile[0].type.includes('gif') &&
            !this.tweet.mediaFile[0].type.includes('video'))
        );
      },
      canAddPollOption() {
        return this.pollOptions.length < 4;
      },
      showAddButton() {
        return (this.tweet.media || this.status !== '') && this.showAddTweetButton;
      },
      statusPlaceholder() {
        const firstTweetPlaceholder = this.isScheduleReply
          ? 'What would you like to reply with?'
          : Math.random() >= 0.5
          ? 'Tip: paste a long text and it will be turned into a thread!'
          : 'What would you like to share?';
        if (this.tweet.count === 0) {
          return firstTweetPlaceholder;
        }
        return 'Tweet ' + (this.tweet.count + 1);
      },
      isDisabledGifSelector() {
        return Boolean(
          this.disableMediaUpload ||
            !this.canAddMedia ||
            this.tweet.mediaFile.length > 0 ||
            (this.showFacebookBlock && this.imageCountInThread > 0)
        );
      },
      isThreadFinisherTweet() {
        return (
          this.hasThreadFinisherTweet &&
          this.threadLength === this.tweet.count + 1 &&
          this.threadLength !== 1
        );
      },
    },
    mounted() {
      this.$refs.tweet.focus();
      this.setThreadAsFavorite();
      this.$el.addEventListener('paste', e => {
        this.pastedFiles = [];
        if (!this.canAddMedia || e.clipboardData.files.length === 0) {
          return;
        }
        this.pastedFiles.push(e.clipboardData.files[0]);
      });
      if (this.tweet.poll) {
        this.isPoll = true;
        this.pollOptions = this.tweet.poll.options;
        this.pollDuration = this.tweet.poll.duration;
      } else {
        this.pollOptions = [undefined, undefined];
        this.pollDuration = { days: 1, hours: 0, minutes: 0 };
      }
    },
    methods: {
      formatTweet(status) {
        return formatTweet(status).replace(/\n/g, '<br />');
      },
      getUploadContainerTooltip(isGIFButton) {
        if (this.showFacebookBlock && !this.canAddMediaToFacebook) {
          return 'Facebook only supports posting either 1 GIF, 1 video or multiple images';
        }

        if (this.showFacebookBlock && this.imageCountInThread > 0 && isGIFButton) {
          return 'Facebook only supports posting either 1 GIF, 1 video or multiple images';
        }

        if (this.showLinkedInBlock && !this.canAddMediaToLinkedIn) {
          return 'LinkedIn only supports posting either 1 video or up to 9 images';
        }

        if (
          (Array.isArray(this.tweet.mediaFile) &&
            this.tweet.mediaFile.length === 1 &&
            (this.tweet.mediaFile[0].type.includes('gif') ||
              this.tweet.mediaFile[0].type.includes('video'))) ||
          (Array.isArray(this.tweet.mediaFile) &&
            ((this.tweet.mediaFile.length === 4 &&
              this.tweet.mediaFile[0].type.includes('image')) ||
              (isGIFButton && this.tweet.mediaFile.length > 0)))
        ) {
          return 'You can choose either 1 GIF, 1 video or up to 4 images';
        }

        if (this.tweetHasPoll) {
          return 'Media upload is disabled because the tweet has a poll';
        }

        if (isGIFButton) {
          return 'Choose GIF';
        }

        return 'Upload Media';
      },
      addPollOption() {
        this.pollOptions.push(undefined);
      },
      deleteReplacedMedia(replacedMediaNames) {
        this.$emit('deleteReplacedMedia', replacedMediaNames);
      },
      setThreadAsFavorite() {
        if (this.threadIsFavorite && this.tweet.count === 0) {
          this.isFavorite = true;
        }
      },
      async fillWithGeneratedQuote() {
        this.$eventStore.generateQuote();
        this.isGeneratingQuote = true;
        const quote = (await controller.getGeneratedQuote(this.currentUser)).quote;
        this.isGeneratingQuote = false;
        this.quote = quote;
        this.$emit('status-updated', quote, true);
        dao.userProfile.saveGeneratedQuote(this.userProfile.uid, quote);
      },
      onBlur(event) {
        this.$emit('on-blur', event);
      },
      onClickOutside(event) {
        // Will lose focus only when the clicked element is another textarea
        if (event.target.localName !== 'textarea') {
          return null;
        }
        this.shouldEnableGrammarly = false;
        this.key = Date.now().toString();
        this.$nextTick(() => {
          this.previousHeight = adjustTextAreaHeight(this.$refs.tweet, this.$refs.tweet_container, this.previousHeight);
        })
      },
      watchWriting(event) {
        this.$emit('writing', event);
      },
      pressedSave(event) {
        this.$emit('pressed-save', event);
      },
      onInputChange(event, index) {
        this.$emit('input-changed', event, index);
      },
      onPaste(event) {
        this.$emit('pasted', event);

        if (this.isThreadFinisherTweet) return;

        const link = event.clipboardData.getData('text');
        this.setQuoteURLIfValid(link);
      },
      updateStatus: lodash.debounce(function (event) {
        this.$emit('status-updated', this.status);
        this.onInputChange(event, this.tweet.count);
      }, 50, {maxWait: 100}),
      deleteTweet(tweet) {
        this.$emit('deleted', tweet, this.isThreadFinisherTweet);
      },
      addTweetBox(tweet) {
        this.$emit('added-new-tweet', tweet);
      },
      openGifSelector(tweetIndex) {
        this.$emit('open-gif-selector', tweetIndex);
      },
      clearMediaSelectionAtIndex(tweet, index) {
        this.$emit('cleared-media', tweet, index);
      },
      toggleFavorite() {
        this.isFavorite = !this.isFavorite;
        this.$emit('set-as-favorite', this.isFavorite);
      },
      togglePoll() {
        this.isPoll = !this.isPoll;
        if (this.isPoll) {
          const poll = {
            duration: this.pollDuration,
            options: this.pollOptions,
          };
          this.$emit('update-poll', poll);
        } else {
          this.$emit('delete-poll');
        }
      },
      togglePollLength() {
        this.isEditingPollLength = !this.isEditingPollLength;
      }
    },
  };
</script>
