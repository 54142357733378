<template>
  <div class="space-y-3">
    <div class="flex items-center justify-between">
      <div class="flex items-center font-medium leading-none">
        <span>Post on LinkedIn</span>
        <info-tooltip content="Post this tweet on LinkedIn!"></info-tooltip>
      </div>
      <base-switch
        @input="updateEnabled"
        :disabled="disabled"
        v-model="showLinkedInBlock"
      ></base-switch>
    </div>
    <div v-if="showLinkedInBlock" class="relative mt-2 mb-2 lg:mt-0 flex items-center space-x-2">
      <label class="custom-option" v-for="{ type, name } in postsTypes" :key="type" :data-cy="type">
        <input type="radio" name="postType" :id="type" :value="type" v-model="postType" />
        <span>{{ name }}</span>
      </label>
    </div>
    <div
      class="text-warning mt-2"
      v-if="showLinkedInBlock && !isUserConnectedToLinkedIn && postType !== 'carousel'"
    >
      You can schedule a LinkedIn post but it won't be published unless you
      <router-link to="/settings#linkedin">connect your LinkedIn account to Hypefury</router-link>.
    </div>
    <div
      v-show="
        postType !== 'carousel' &&
        showLinkedInBlock &&
        isUserConnectedToLinkedIn &&
        isUserPlanStandardOrBelow(userProfile.customerStatus)
      "
      class="mt-2 text-warning leading-snug flex items-center sm:space-x-2"
    >
      <inline-svg src="/img/icons/premium.svg" class="hidden sm:block" />
      <span
        >This won't be posted to LinkedIn unless you
        <upgrade-to-premium-link
          >upgrade to {{ getPlanLabel('premium') }}</upgrade-to-premium-link
        ></span
      >
    </div>
    <div
      v-show="showLinkedInBlock && postType === 'carousel' && !doesUserHaveNotificationsEnabled"
      class="mt-2 text-warning leading-snug flex items-center sm:space-x-2"
    >
      <span v-if="isEnablingNotifications">Enabling notifications...</span>
      <span v-else
        >If you want to be notified when your LinkedIn Carousel is ready you'll need to enable
        them <a href="javascript:;" @click="enableNotifications">here</a>.</span
      >
    </div>
    <div
      v-if="
        showLinkedInBlock &&
        isUserConnectedToLinkedIn &&
        linkedInTokenInfo &&
        linkedInTokenInfo.isExpired &&
        postType !== 'carousel'
      "
      class="mt-2 text-warning leading-snug flex items-center sm:space-x-2"
    >
      <inline-svg src="/img/icons/info.svg" class="hidden sm:block" />
      <span>
        This won't be posted to LinkedIn unless you
        <router-link to="/settings">re-authenticate with your LinkedIn account</router-link>
      </span>
    </div>
    <div
      v-if="
        showLinkedInBlock &&
        isUserConnectedToLinkedIn &&
        linkedInTokenInfo &&
        !linkedInTokenInfo.isExpired &&
        linkedInTokenInfo.expiresInDays <= 7 &&
        postType !== 'carousel'
      "
      class="mt-2 text-warning leading-snug flex items-center sm:space-x-2"
    >
      <inline-svg src="/img/icons/info.svg" class="hidden sm:block" />
      <span>
        Your LinkedIn credentials expire in {{ linkedInTokenInfo.expiresInDays }} days. To prevent
        any interruptions in your posting to LinkedIn
        <router-link to="/settings">refresh the credentials for your LinkedIn account</router-link>
      </span>
    </div>
    <div class="space-y-3" v-if="showLinkedInBlock">
      <template v-if="postType !== 'carousel'">
        <div v-if="isUserConnectedToLinkedIn">
          <div v-if="Array.isArray(linkedInPages) && linkedInPages.length > 1" class="space-y-1">
            <span>Post as:</span>
            <div class="space-y-1">
              <div v-for="page of linkedInPages" :key="page.id">
                <label class="cursor-pointer space-x-2">
                  <input class="cursor-pointer" type="checkbox" :value="page.id" v-model="selectedLinkedInIds" @change="handleLinkedInCheckboxChange" />
                  <span>{{ page.name }}</span>
                </label>
              </div>
            </div>
          </div>
          <div v-else-if="!linkedInPages">
            <span class="text-secondary">Fetching your LinkedIn info. Please wait...</span>
          </div>
        </div>

        <base-input ref="textarea-container" inputGroupClasses="textarea-container" alternative>
          <textarea
            data-cy="linkedin-textarea"
            dir="auto"
            class="form-control simple"
            :disabled="disabled"
            ref="linkedInText"
            rows="3"
            v-model="linkedInText"
            @input="didUserEditLinkedInText = true"
            maxlength="3000"
            id="linkedInText"
          ></textarea>
          <div class="thread-actions flex item-center p-1">
            <div class="form-group flex items-center ml-2 space-x-2" v-if="showLinkedInBlock">
              <base-switch
                data-cy="linkedin-publicity"
                labelText="Post Publicly"
                v-model="isPostPublic"
              ></base-switch>
            </div>
            <div class="ml-auto flex items-center space-x-1">
              <div :class="charactersLeftClass" class="character-count">
                {{ charactersLeftCounter }}
              </div>
            </div>
          </div>
        </base-input>
      </template>
      <template v-else>
        <div class="flex items-center justify-between my-2">
          <div class="flex items-center font-weight-medium leading-none">
            <span>Add LinkedIn thread finisher</span>
          </div>
          <base-switch :disabled="disabled" v-model="isThreadFinisherEnabled" data-cy="linkedin-finisher-switch"></base-switch>
        </div>

        <base-input
          v-if="isThreadFinisherEnabled"
          ref="carousel-textarea-container"
          inputGroupClasses="textarea-container"
          alternative
          data-cy="linkedin-carousel-input"
        >
          <textarea
            dir="auto"
            class="form-control simple"
            ref="threadFinisherTextarea"
            rows="3"
            v-model="threadFinisherText"
            maxlength="280"
            data-cy="linkedin-finisher-textarea"
          ></textarea>
          <div class="thread-actions flex item-center p-1">
            <div class="ml-auto flex items-center space-x-1">
              <div :class="threadFinisherCharactersLeftClass" class="character-count">
                {{ threadFinisherCharactersLeftCounter }}
              </div>
            </div>
          </div>
        </base-input>
        <p class="my-2 text-warning font-bold">
          You will receive an email and a notification when all the tweets have been published and the
          carousel is ready to be downloaded.
        </p>
        <img src="/img/linkedin_carousel.gif" />
      </template>
      <div v-if="isNewComposer" class="flex justify-end">
        <button
          data-cy="save-button"
          class="btn text primary"
          :disabled="!canSaveLinkedInText"
          @click="
            () =>
              $emit(
                'updateLinkedInInfoInParent',
                linkedInText,
                isPostPublic,
                selectedLinkedInIds,
                postType,
                isThreadFinisherEnabled,
                threadFinisherText
              )
          "
        >
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import lodash from 'lodash';
  import { mapGetters } from 'vuex';
  import EnableNotificationsMixin from '../../views/Mixins/EnableNotificationsMixin.vue';
  import LinkedInMixin from '../../views/Mixins/LinkedInMixin.vue';
  import { countTweetLength } from '@/../functions/src/util/countTweetLength';
  import CustomerStatusMixin from '../../views/Mixins/CustomerStatusMixin.vue';

  export default {
    name: 'linkedin',
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
      thread: Object,
      value: {
        type: Boolean,
        default: false,
      },
      isNewComposer: {
        type: Boolean,
        default: false,
      },
      showLinkedInBlockInParent: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        showLinkedInBlock: false,
        linkedInText: '',
        didUserEditLinkedInText: false,
        isPostPublic: true,
        visibilityOptions: ['private', 'public'],
        postsTypes: [
          { type: 'text', name: 'Text' },
          { type: 'carousel', name: 'Carousel' },
        ],
        postType: 'text',
        isThreadFinisherEnabled: false,
        threadFinisherText: '',
      };
    },
    computed: {
      charactersLeftClass() {
        if (this.linkedInText.length >= 3000) return 'character-count-exceeded';
        if (this.linkedInText.length > 3000 - 100) return 'character-count-warning';
        return 'bg-light';
      },
      charactersLeftCounter() {
        return this.linkedInText.length;
      },
      threadFinisherCharactersLeftClass() {
        if (this.threadFinisherText.length >= 280) return 'character-count-exceeded';
        if (this.threadFinisherText.length > 280 - 20) return 'character-count-warning';
        return 'bg-light';
      },
      threadFinisherCharactersLeftCounter() {
        return this.threadFinisherText.length;
      },
      canSaveLinkedInText() {
        if (this.postType === 'carousel') {
          return (
            !this.isThreadFinisherEnabled ||
            (countTweetLength(this.threadFinisherText) > 0 &&
              countTweetLength(this.threadFinisherText) <= 280)
          );
        } else {
          return (
            this.linkedInText.trim().length > 0 &&
            this.linkedInText.trim().length <= 3000 &&
            (this.isUserConnectedToLinkedIn ? this.selectedLinkedInIds.length > 0 : true)
          );
        }
      },
      ...mapGetters({ userProfile: 'getUserProfile' }),
    },
    methods: {
      updateEnabled() {
        this.$emit('input', this.showLinkedInBlock);
      },
    },
    mounted() {
      if (this.thread && this.thread.linkedIn) {
        this.showLinkedInBlock = true;

        if (this.thread.linkedIn.postType === 'carousel') {
          this.postType = this.thread.linkedIn.postType;
          this.isThreadFinisherEnabled = Boolean(this.thread.linkedIn.threadFinisherText);
          this.threadFinisherText = lodash.get(this.thread, 'linkedIn.threadFinisherText', '');
        } else if (this.thread.linkedIn.text) {
          this.didUserEditLinkedInText = true;
          this.linkedInText = this.thread.linkedIn.text;
          this.isPostPublic = this.thread.linkedIn.isPostPublic;
          this.selectedLinkedInIds = this.thread.linkedIn.idsToPostAs;
          this.linkedInText = this.thread.linkedIn.text;
        }

        this.updateEnabled();
      }

      if (!this.thread) {
        this.showLinkedInBlock = lodash.get(
          this,
          'userProfile.settings.isShareOnLinkedInEnabled',
          false
        );
      }

      this.$emit('resetLinkedInManualEdit', () => {
        this.didUserEditLinkedInText = false;
      });

      this.$emit(
        'updateLinkedInInfoFromParentToChildFunction',
        (text, isPostPublic, selectedLinkedInIds, hasUserSavedLinkedInSettings) => {
          if (!this.didUserEditLinkedInText || hasUserSavedLinkedInSettings) this.linkedInText = text;
          this.isPostPublic = isPostPublic;
          this.selectedLinkedInIds = selectedLinkedInIds;
        }
      );
    },
    watch: {
      showLinkedInBlockInParent(val) {
        if (val) {
          this.showLinkedInBlock = true;
        }
      },
      value(val) {
        this.showLinkedInBlock = val;
      },
      disabled(val) {
        if (val) {
          this.showLinkedInBlock = false;
          this.updateEnabled();
        }
      },
      isPostPublic(val) {
        if (this.isNewComposer) {
          return;
        }
        this.$emit(
          'updateLinkedInInfoInParent',
          this.linkedInText,
          val,
          this.selectedLinkedInIds,
          this.postType,
          this.isThreadFinisherEnabled,
          this.threadFinisherText
        );
      },
      linkedInText(val) {
        if (this.isNewComposer && this.didUserEditLinkedInText) {
          return;
        }
        this.$emit(
          'updateLinkedInInfoInParent',
          val,
          this.isPostPublic,
          this.selectedLinkedInIds,
          this.postType,
          this.isThreadFinisherEnabled,
          this.threadFinisherText
        );
      },
      selectedLinkedInIds(val) {
        if (this.isNewComposer) {
          return;
        }
        this.$emit(
          'updateLinkedInInfoInParent',
          this.linkedInText,
          this.isPostPublic,
          val,
          this.postType,
          this.isThreadFinisherEnabled,
          this.threadFinisherText
        );
      },
      postType(val) {
        if (val === 'carousel' && this.isThreadFinisherEnabled) {
          setTimeout(() => this.$refs.threadFinisherTextarea.focus(), 100);
        }
        if (val === 'text') {
          setTimeout(() => this.$refs.linkedInText.focus(), 100);
        }

        if (this.isNewComposer) {
          return;
        }
        this.$emit(
          'updateLinkedInInfoInParent',
          this.linkedInText,
          this.isPostPublic,
          this.selectedLinkedInIds,
          val,
          this.isThreadFinisherEnabled,
          this.threadFinisherText
        );
      },
      threadFinisherText(val) {
        if (this.isNewComposer) {
          return;
        }
        this.$emit(
          'updateLinkedInInfoInParent',
          this.linkedInText,
          this.isPostPublic,
          this.selectedLinkedInIds,
          this.postType,
          this.isThreadFinisherEnabled,
          val
        );
      },
      isThreadFinisherEnabled(val, old) {
        if (!old && val) {
          setTimeout(() => this.$refs.threadFinisherTextarea.focus(), 100);
        }

        if (this.isNewComposer) {
          return;
        }

        this.$emit(
          'updateLinkedInInfoInParent',
          this.linkedInText,
          this.isPostPublic,
          this.selectedLinkedInIds,
          this.postType,
          val,
          this.threadFinisherText
        );
      },
      showLinkedInBlock(val) {
        if (val) {
          setTimeout(() => {
            if (this.$refs.linkedInText) {
              this.$refs.linkedInText.focus();
            }
          }, 100);
        }
      },
    },
    mixins: [LinkedInMixin, EnableNotificationsMixin, CustomerStatusMixin],
  };
</script>
