<template>
  <div class="gif-selector-wrapper p-4">
    <div ref="hypefuryGifSelector"></div>
    <base-button
      v-if="showGoBackButton"
      class="button secondary-button small-button"
      @click="close"
      :disabled="isLoading"
      >Go back</base-button
    >
  </div>
</template>

<script>
  import GifSelector from '@/gif-selector';
  import '@/assets/css/gif-selector.css';
  import { httpRoutesURL } from '@/config';
  import controller from '@/controller';
  import { mapGetters } from 'vuex';

  export default {
    props: {
      index: Number,
      disabled: {
        value: Boolean,
        default: false,
      },
      showGoBackButton: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        options: {
          baseUrl: httpRoutesURL + '/tenor',
        },
        isLoading: false,
      };
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
    },
    mounted() {
      this.initialize();
    },
    methods: {
      close() {
        this.$emit('close-gif-selector');
      },
      initialize() {
        const callbacks = {
          selected: this.chooseGif,
          trending: this.loadTrending,
          search: this.search,
          loading: this.setIsLoading,
        };
        GifSelector.initialize(this.$refs.hypefuryGifSelector, this.options, callbacks);
      },
      setIsLoading(val) {
        this.isLoading = val;
      },
      async loadTrending() {
        const response = await controller.getTrendingGifs(this.currentUser);
        return response;
      },
      async search(query) {
        const response = await controller.searchGifs(this.currentUser, query);
        return response;
      },
      chooseGif(url) {
        this.$emit('selected-gif', url, this.index);
        this.$emit('close-gif-selector');
      },
    },
  };
</script>
