<template>
  <div>
    <div class="flex items-center justify-between">
      <div class="flex items-center font-medium leading-none">
        <span>Post on Facebook</span>
        <info-tooltip :content="tooltip"></info-tooltip>
      </div>
      <base-switch
        @input="updateEnabled"
        :disabled="disabled"
        v-model="showFacebookBlock"
      ></base-switch>
    </div>
    <div class="text-warning mt-2" v-if="showFacebookBlock && !isFacebookPageConnected && !isAnyFacebookGroupSelected">
      You can schedule a Facebook post but it won't be published unless you
      <router-link to="/settings#facebook">connect your Facebook page or group to Hypefury</router-link>.
    </div>
    <div class="mt-3 space-y-3" v-if="showFacebookBlock">
      <base-input ref="textarea-container" inputGroupClasses="textarea-container" alternative>
        <textarea
          data-cy="facebook-textarea"
          dir="auto"
          class="form-control simple"
          :disabled="disabled"
          ref="facebookText"
          rows="3"
          v-model="facebookText"
          maxlength="63206"
          @input="didUserEditFacebookText = true"
          id="facebookText"
        ></textarea>
        <div class="thread-actions flex item-center p-1">
          <div class="ml-auto flex items-center space-x-1">
            <div :class="charactersLeftClass" class="character-count">
              {{ charactersLeftCounter }}
            </div>
          </div>
        </div>
      </base-input>

      <div v-if="isNewComposer" class="flex justify-end">
        <button data-cy="save-button" class="btn text primary" :disabled="!canSaveFacebookText" @click="() =>
          $emit('updateFacebookInfoInParent', facebookText)">
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import FacebookMixin from '../../views/Mixins/FacebookMixin.vue';
  import lodash from 'lodash';

  export default {
    name: 'facebook',
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
      thread: Object,
      value: {
        type: Boolean,
        default: false,
      },
      isNewComposer: {
        type: Boolean,
        default: false,
      },
      tooltip: {
        type: String,
        default: 'Post this tweet on Facebook!',
      },
      showFacebookBlockInParent: Boolean,
    },
    data() {
      return {
        showFacebookBlock: false,
        facebookText: '',
        didUserEditFacebookText: false,
      };
    },
    computed: {
      charactersLeftClass() {
        if (this.facebookText.length >= 63206) return 'character-count-exceeded';
        if (this.facebookText.length > 63206 - 100) return 'character-count-warning';
        return 'bg-light';
      },
      charactersLeftCounter() {
        return this.facebookText.length;
      },
      canSaveFacebookText() {
        return this.facebookText.trim().length > 0 && this.facebookText.trim().length <= 63206;
      },
      ...mapGetters({ userProfile: 'getUserProfile' }),
    },
    methods: {
      updateEnabled() {
        this.$emit('input', this.showFacebookBlock);
      },
    },
    mounted() {
      if (this.thread && this.thread.facebook && this.thread.facebook.text) {
        this.showFacebookBlock = true;
        this.didUserEditFacebookText = true;
        this.facebookText = this.thread.facebook.text;
        this.updateEnabled();
      }

      if (!this.thread) {
        this.showFacebookBlock = lodash.get(
          this,
          'userProfile.settings.isShareOnFacebookEnabled',
          false
        );
      }

      this.$emit('updateFacebookInfoFromParentToChildFunction', (text, hasUserSavedFacebookText) => {
        if (!this.didUserEditFacebookText || hasUserSavedFacebookText) this.facebookText = text;
      });
    },
    watch: {
      showFacebookBlockInParent(val) {
        if (val) {
          this.showFacebookBlock = true;
        }
      },
      value(val) {
        this.showFacebookBlock = val;
      },
      disabled(val) {
        if (val) {
          this.showFacebookBlock = false;
          this.updateEnabled();
        }
      },
      facebookText(val) {
        if (this.isNewComposer && this.didUserEditFacebookText) {
          return;
        }
        this.$emit('updateFacebookInfoInParent', val);
      },
      showFacebookBlock(val) {
        if (val) {
          setTimeout(() => {
            this.$refs.facebookText && this.$refs.facebookText.focus();
          } , 100);
        }
      },
    },
    mixins: [FacebookMixin],
  };
</script>