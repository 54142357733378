<script>
  import NewThreadModal from '../Modals/NewThreadModal';
  import { EventBus } from '@/event-bus';
  export default {
    components: {
      NewThreadModal,
    },
    methods: {
      addTweetToCategory(category) {
        EventBus.$emit('open-new-composer', {
          categoryId: category.id,
          postType: 'recurrent',
          source: {
            name: 'Category Posts',
            params: {
              categoryId: category.id,
            },
          },
        });
      },
    },
  };
</script>
