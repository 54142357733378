var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"modal fade",class:[
    { 'show d-block': _vm.show },
    { 'd-none': !_vm.show },
    { 'modal-mini': _vm.type === 'mini' },
    { 'global-modal': _vm.isGlobal },
  ],attrs:{"tabindex":"0","role":"dialog","aria-hidden":!_vm.show},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.closeModal.apply(null, arguments)}}},[_c('div',{staticClass:"flex justify-center w-full",style:([ _vm.isFocusModeEnabled  ? { 'height' : '100%' } : { 'max-height' : '90vh' } ])},[_c('div',{staticClass:"modal-dialog modal-dialog-centered",class:[{ 'modal-notice': _vm.type === 'notice', [`modal-${_vm.size}`]: _vm.size }, _vm.modalClasses]},[_c('div',{staticClass:"modal-content",class:[_vm.gradient ? `bg-gradient-${_vm.gradient}` : '', _vm.modalContentClasses]},[_c('div',{staticClass:"md:hidden block"},[_vm._t("close-icon")],2),(_vm.$slots.header && _vm.$slots.tabs)?_c('div',{staticClass:"tabs-modal-header",class:[_vm.headerClasses]},[_c('div',{staticClass:"flex justify-between",class:{ 'focus-mode-hide': _vm.selectedIndex !== 0 }},[_vm._t("header"),_vm._t("close-button",function(){return [(_vm.showClose)?_c('button',{staticClass:"focus--hide",attrs:{"data-cy":"modal-close","type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":_vm.closeModal}},[_c('span',{attrs:{"aria-hidden":!_vm.show}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M6 18L18 6M6 6l12 12","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])])]):_vm._e()]})],2),(_vm.tabs)?_c('div',{staticClass:"flex justify-between mt-4 tabs-container focus--hide"},[_c('div',{staticClass:"flex justify-between space-x-4 tabs"},_vm._l((_vm.tabs),function(tab,index){return _c('div',{key:index,class:{
                'tab-header': index == _vm.selectedIndex,
                'disabled': !_vm.enabled,
                updated: _vm.mustVisitAdvancedTab && tab.elm.title == 'Advanced',
              },attrs:{"data-cy":"tab-title"},on:{"click":function($event){return _vm.selectTab(index)}}},[_vm._v(" "+_vm._s(tab.elm.title)+" ")])}),0),_c('div',{staticClass:"text-sm",attrs:{"data-cy":"time-selector"}},[_vm._t("dateTime")],2)]):_vm._e()]):_vm._e(),_c('div',{staticClass:"modal-body without-scrollbar",class:_vm.bodyClasses},[_c('div',{staticClass:"modal-body-container",class:{'min-height-80vh' : _vm.isFocusModeEnabled}},[_c('div',{staticClass:"m-auto min-width-600"},[_vm._t("focus"),_vm._t("tabs"),_vm._t("actions")],2),_vm._t("tweet-previewer")],2)]),_c('div',{staticClass:"md:block hidden"},[_vm._t("close-icon")],2),(_vm.$slots.footer)?_c('div',{staticClass:"modal-footer",class:_vm.footerClasses},[_vm._t("footer")],2):_vm._e(),_vm._t("overlay"),_vm._t("fade")],2)])]),_vm._t("previewer")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }